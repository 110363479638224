/* HeroBanner.module.css */

.mainContainer {
  background-color: var(--black);
  height: calc(100vh - 60px);
  min-height: 400px;
  padding-top: 20px;
  overflow: hidden;
 width: auto;
}

.container {
  display: flex;
  max-height: calc(100vh - 60px);
  margin: 0px 200px 80px 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-shrink: 0;
  position: relative;
}

.heroTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.heroH1 {
  color: var(--white);
  margin: 0;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.heroSubText {
  color: var(--white);
  margin: 0;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.heroButton {
  color: black;
  margin-top: 30px;
  border: 0;
  width: 175px;
  display: flex;
  padding: 15px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 100px;
  background: var(--white) ;
  font-size: 20px;
  cursor: pointer;
  z-index: 20;
}

.heroButton:hover {
  background-color:#61487C;
  color: var(--white);
}

.heroImageContainer {
  position: absolute;
  top: 160px;
  display: flex;
  justify-content: center;
  height: 100%;
}
.heroVideo {
  height: 70vh;
}

@media (max-width: 768px) {
  .mainContainer {
    min-width: 0;
  }
  .container {
    margin: 40px 20px 0px 20px;
    height: 100vh;
    justify-content: flex-start;
  }
  .heroImageContainer {
    top: 70px;
  }
  .heroTextContainer {
    width: 100%;
  }
  .heroH1 {
    color: var(--white);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 12px;
  }
  .heroSpan {

    font-size: 20px;
    font-weight: 600;
  }
  .heroSubText {
    color: var(--white);

    font-size: 14px;

    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .heroButton {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

  
    width: 120px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .heroVideo {
    width: 180%;
  }
}
@media (max-height: 560px){
  .heroH1 {
    font-size: 25px;
  }
  .heroButton{
    padding: 10px 16px;
    width: 120px;
    font-size: 12px;
  }
  .heroSubText{
    font-size: 15px;
  }
  .heroImageContainer{
    top: 110px;
  }
}