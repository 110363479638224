.container {
  height: 153px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);

}
.partner {
  color: var(--white);
  cursor: default;
  text-align: center;
  font-size: 32px;
  padding-right: 100px;
}
@media (max-width: 768px) {
  .container{
    background-color:#251436 ;
    height: 58px;
    min-width: 0;
  }
  .partner{
font-size: 12px;
padding-right: 50px;
  }
}