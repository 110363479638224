.container {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;
  flex-direction: column;
  min-height: 51%;
  max-width: 340px;
  min-width: 250px;
  width: 100%;
  overflow-y: scroll;
}
.title {
  margin: 20px;
  color: var(--dashbord-primary);
font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}
.cardsContainer {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;
}
.cardsContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}
.container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.card {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 2px 0px #fff inset;
  display: flex;
  padding: 10px;
  flex-direction: column;
}
.cardTitle {
  color: var(--dashbord-primary);
font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}
.cardText {
  color: var(--dashbord-primary);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.cardSubText {
  color: #65677D;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
@media (max-width: 768px) {
  .container {
    background: 0;
    box-shadow: none;
    border-radius: 0;
    min-height: 0;
    overflow-x: auto;
    min-width: 420px;
    width: 400px;
    scroll-snap-type: y mandatory;
  }
  .title {
    display: none;
  }
  .cardsContainer {
    display: flex;
    flex-direction: row;
overflow: auto;
    margin: 1px;
  width: 110%;
  gap: 20px;


  }
  .cardsContainer::-webkit-scrollbar,
  .container::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
  
 

  
 

  
  .card {
    width: 110px;
    height: 100px;
  }
  .cardTitle {
    font-size: 10px;
  }
  .cardText {
    font-size: 20px;
  }
  .cardSubText {
    font-size: 10px;
  }
}
@media (max-height: 560px){
  .title{
    margin: 10px 20px;

  }
  .cardsContainer{
    margin: 10px 20px;
  }
  .cardTitle{
    margin: 0;
  }
}