.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
height: 100%;

}
.container {
  margin:  0;
  display: flex;
  width: 500px;
  min-height: 300px;
  max-height: 650px;
  height: 80%;
  padding: 20px 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: var(--white);
  flex-direction: column;
  overflow: scroll;
  position: relative;
  margin: 100px;
}
.container::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}
.accountTypeMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  margin: 0;
  color: var(--dark-purple, #251436);
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.accountTypeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 100px;
  cursor: pointer;
}
.accountType1 {
  background: var(--input-bg);
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  transition: background-color 1s ease;
  color: var(--dark-purple, #251436);

font-family: Inter;
font-size: 12px;
}
.highlight1 {
  background: var(--dark-purple, #251436);
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  transition: background-color 1s ease;
  color: #FFF;

font-family: Inter;
font-size: 12px;
}
.accountType2 {
  background: var(--input-bg);
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  transition: background-color 0.3s ease;
  color: var(--dark-purple, #251436);

font-family: Inter;
font-size: 12px;
}
.highlight2 {
  background: var(--dark-purple, #251436);
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  transition: background-color 0.3s ease;
  color: #FFF;

font-family: Inter;
font-size: 12px;
}
.accountTypeTitle {
  color: var(--black);
margin: 10px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.personalFormOptionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.personalOptionsTitle{
  margin: 10px;
}
.checkBoxConatainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.checkbox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inputContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
  gap: 20px;
  width: 100%;
}
.inputContainerEnterprise {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 20px;
}
.inputContainerDubble {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  gap: 20px;
}
.errorIconContainer {
  position: absolute;
  top: 7px;
  right: 15px;
}
.errorIconContainerEnterprise {
  position: absolute;
  top: 7px;
  right: 15px;
}
.inputFieldError {
  padding: 0px 20px;
  height: 35px;
  align-items: center;
  border-radius: 25px;
  background: var(--input-bg);
  border: var(--input-error-red) 1px solid;
  color: var(--dark-purple, #251436);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: auto;
}
.inputFieldError:focus {
  outline: var(--input-error-red) 1px solid;
}
.inputContainerSub {
  width: 100%;
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
}

.inputField {
  display: flex;
  padding: 0px 20px;
  height: 35px;
  align-items: center;
  border-radius: 25px;
  background: var(--input-bg);
  border: transparent 1px solid;
  color: var(--dark-purple, #251436);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.inputFieldEnterprise {
  display: flex;
  padding: 0px 25px;
  height: 35px;
  align-items: center;
  border-radius: 25px;
  background: var(--input-bg);
  border: transparent 1px solid;
  color: var(--input-placeholder);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.button {
  width: 100%;
  display: flex;
  height: 35px;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--dark-purple, #251436);
  border: 0;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
}
.button:hover {
  background-color: #61487C;
}
.signUpText {
  text-align: center;
  color: #292929;
margin: 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.highlight {
  color: #9E7AC3;
cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.errorMessage {
  color: var(--input-error-red);
  height: 5px;
  margin: 5px;
  margin-left: 20px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
}

.passwordCheckNotPassed {
  color: #292929;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
  margin: 0;
}
.passwordCheckPassed {
  color: var(--lilac, #9E7AC3);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  margin: 0;
}
.showPassword {
  position: absolute;
  right: 45px;
  top: 7px;
}
.passwordCheck {
  height: 0; 
  opacity: 0;
  transition: height 0.5s ease; 
overflow: hidden;
}
.showPasswordCheck {
  opacity: 1;
  height: 60px;
  transition: height 0.5s ease;
}
.form{
  width: 100%;
}
.personalFormContainer{
  width: 100%;
}
.closeBubble{
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.divider {
  position: relative;
  margin: 20px 0px;
  width: 100%;
}
.hr {
  height: 0.5px;
  flex: 1 0 0;
  background: var(--input-placeholder);
}
.dividerText {
  display: flex;
  padding: 0 10px;
  background-color: var(--white);
  position: absolute;
  top: -15px;
  right: 50%;
  transform: translateX(50%);
  color: var(--black);
  font-family: Inter;
  font-size: 10px;
}
.mobileButton {
  width: 100%;
  display: flex;
  height: 35px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 100px;
  background: var(--input-bg);
cursor: pointer;
  color: var(--black);
  font-family: Poppins;
  font-size: 16px;
  border: 0;
}
.mobileButton:hover{
  background-color: #61487C;
  color: white;
}
@media (max-width: 768px) {
  .container {
    display: flex;
    width: 80vw;
    padding: 40px 20px 20px 20px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
  .title {
    color: #000;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .accountTypeTitle {
    color: #000;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .accountType1 {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .highlight1 {
    background: var(--dark-purple, #251436);
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    transition: background-color 1s ease;
    color: white;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .accountType2 {
    height: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .highlight2 {
    background: var(--dark-purple, #251436);
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    transition: background-color 1s ease;
    color: white;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .divider {
    position: relative;
    margin: 20px 0px;
    width: 100%;
  }
  .hr {
    height: 0.5px;
    flex: 1 0 0;
    background: var(--input-placeholder);
  }
  .dividerText {
    display: flex;
    padding: 0 10px;
    background-color: var(--white);
    position: absolute;
    top: -15px;
    right: 50%;
    transform: translateX(50%);
    color: var(--black);
    font-family: Inter;
    font-size: 10px;
  }
  .mobileButton {
    display: flex;
    height: 35px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
    background: var(--input-bg);

    color: var(--black);
    font-family: Poppins;
    font-size: 12px;
    border: 0;
  }
  .mobileButton:active {
    background-color: #61487C;
    color: var(--white);
  }
  .signUpText {
    display: none;
  }
  .personalOptionsTitle {
    color: #000;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .checkBoxOption {
    color: #000;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .inputContainerSub {
    margin: 0;
    position: relative;
    justify-content: center;
  }
  .inputField {
    display: flex;
    height: 35px;
    padding: 10px 15px;
    align-items: center;
    color: #292929;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1 0 0;
    width: auto;
  }
  .inputField:focus{
    outline: none;
  }
  .inputFieldEnterprise {
    display: flex;
    padding: 17px 25px;
    align-items: center;
    border-radius: 25px;
    background: var(--input-bg);
    border: transparent 1px solid;
    color: var(--input-placeholder);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .enterpriseInput:focus{
outline: none;
  }

  .button {
    display: flex;
    height: 35px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: white;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px 0;
    width: 100%;
    margin-top: 0;
  }
  .personalFormOptionsContainer {
    margin-top: 27px;
  }
  .button:active {
    background-color: #61487C;
    color: var(--white);
  }
  .inputFieldError {
    width: auto;
    align-items: center;
    border-radius: 25px;
    background: var(--input-bg);
    border: var(--input-error-red) 1px solid;
    display: flex;
    height: 35px;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: var(--input-placeholder);
    font-family: Inter;
    font-size: 10px;
  }
  .errorIconContainer {
    position: absolute;
    top: 5px;
    right: 15px;
  }
  .errorIconContainerEnterprise {
    position: absolute;
    top: 42px;
    right: 15px;
  }
  .errorMessage {
    height: 15px;
    color: var(--input-error-red);
    font-family: Inter;
    font-size: 10px;
    font-weight: 300;
 margin-left: 13px;
  }
  .enterpriseInput {
    margin: 37px 0 0 0;
    display: flex;
    height: 35px;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: #292929;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .form {
    width: 100%;
  }
  .passwordCheckPassed{
    font-size: 10px;
    height: 15px;
    margin-left: 10px;
  }
  .passwordCheckNotPassed{
    font-size: 10px;
    height: 15px;
    margin-left: 10px;
  }
  .passwordCheckPassed >svg{
    width: 15px;
  }
  .passwordCheckNotPassed>svg{
width: 15px;
  }
  .showPasswordCheck{
    height: 45px;
  }
  .showPassword {
    position: absolute;
    right: 15px;
    top: 5px;
  }
  .inputContainer {
    width: 100%;
    display: flex;
  }
}
@media (max-height: 560px){
.container{
  gap: 0;
  padding: 10px 40px;
}
.inputContainer{
  margin: 0;
}
.title{
  font-size: 20px;
}
.accountTypeTitle{
  font-size: 15px;
}
.personalOptionsTitle{
  font-size: 15px;
}
.inputField,.inputFieldError{
  height: 25px;
}
.accountType1,.accountType2,.highlight1,.highlight2{
  height: 30px;
}
.button,.mobileButton{
  height: 30px;
}
.errorIconContainer{
  top: 2px;
}
}