.mainContainer {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0);

    backdrop-filter: blur(10px);
    overflow: scroll;
  }
  .container {
    display: flex;
    width: 500px;
    padding: 20px 40px;
    min-height: 300px;
    max-height: 650px;
    height: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    border-radius: 20px;
    background: var(--white);
    position: relative;
    overflow: scroll;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;
  
  }
  .closeBubble{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .title {
    color: #251436;
    color: var(--dark-purple, #251436);
font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    text-align: center;
  }
  .subTitle {
    color: var(--light-purple, #4C2B6D);
    text-align: center;
font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  .textBox{
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .mobileButton {
    display: flex;
    height: 35px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
    background: var(--input-bg);
  cursor: pointer;
    color: var(--black);
    font-family: Poppins;
    font-size: 16px;
    border: 0;
    width: 100%;
  }
  .divider {
    position: relative;
    margin: 20px 0px;
    width: 100%;
  }
  .hr {
    height: 0.5px;
    flex: 1 0 0;
    background: var(--input-placeholder);
  }
  .dividerText {
    display: flex;
    padding: 0 10px;
    background-color: var(--white);
    position: absolute;
    top: -15px;
    right: 50%;
    transform: translateX(50%);
    color: var(--black);
font-family: Poppins;
    font-size: 10px;
  }
  .form{
    width: 100%;
    position: relative;
  }
  .button {
    display: flex;
    height: 35px;
    padding: 10px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #251436;
    border: 0;
    color: white;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
  }
  .button:hover{
    background-color: #61487C;
  }
  .mobileButton:hover{
    background-color: #61487C;
    color: white;
  }
  .inputFieldError {
    display: flex;
    padding: 0px 20px;
    height: 35px;
    align-items: center;
    border-radius: 25px;
    background: var(--input-bg);
    border: var(--input-error-red) 1px solid;
    font-size: 16px;
font-family: Poppins;
    width: calc(100% - 40px);
  }
  .inputFieldError:focus {
    outline: var(--input-error-red) 1px solid;
  }
  .errorMessage {
    color: var(--input-error-red);
    margin: 5px;
    margin-left: 20px;
font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    height: 15px;
  }
  .inputField {
    display: flex;
    padding: 0px 20px;
    height: 35px;
    align-items: center;
    border-radius: 25px;
    background: var(--input-bg);
    border: transparent 1px solid;
    color: var(--dark-purple, #251436);
font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: calc(100% - 40px );
  }
  .errorIconContainer {
    position: absolute;
    top: 7px;
    right: 15px;
  }
  @media (max-width: 768px) {
    .container {
        display: flex;
        width: 80vw;
        padding: 40px 20px 20px 20px;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
      }
      .title {
        color: var(--dark-purple, #251436);
    font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .subTitle{
        color: var(--light-purple, #4C2B6D);
text-align: center;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
      }
  }