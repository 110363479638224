.hamburger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.hamburger-menu .bar {
  width: 35px;
  height: 4.77033px;
  border-radius: 2.38516px;
  background-color: var(--white);
  margin: 3px 0;
  transition: 0.4s;
}

.hamburger-menu.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-9px, 10px);
}

.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-4px, -5px);
}
