/* MAIN START*/
.newEventContainer {
  position: relative;
  margin: 10px 0px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}
.mobileOptions{
    display:none;
}
.textarea {
  display: flex;
  width: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  padding: 12px 20px;
  resize: none;
  height: 100px;
  border: 0;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.newEventContainerSuccessfull {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 740px;
  height: 100%;
  margin: 20px 0px;
  margin-bottom: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.successfullContainer {
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
}
.newEventContainerSuccessfullMobile {
  margin-bottom: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.successfullWrapper {
  display: flex;
  padding: 10px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 22px;
}
.successfullText {
  color: var(--Text-dark, #393d63);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin: 0;
}
.scrollable {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.scrollable::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}
.createButton {
  display: inline-flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--light-purple, #61487c);
  color: #fff;
  width: 170px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  position: absolute;
  bottom: 20px;
  right: 90px;
}
.createButtonMobile {
  display: none;
}

/* UPLOAD START*/
.back {
  color: var(--light-purple, #61487c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 15px;
}
.title {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.subTitle {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}




.openUploadContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 50vw;
  padding: 20px 28px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
}

.dropZone {
  border-radius: 10px;
  background: #f8f7fa;
  width: 100%;
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed transparent;
}
.dragOver {
  border: 2px dashed #393d63;
}
.dropTitle {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.openTitle {
  margin: 0;
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex-shrink: 0;
}
.text {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.subtext {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-shrink: 0;
}
.inputContainer {
  display: flex;
  height: 20px;
  padding: 12px 20px;
  align-items: center;
  gap: 20px;
  width: 45vw;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
}
.input {
  display: flex;
  width: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  padding: 12px 20px;

  height: 30px;
  border: 0;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.error {
  border: red 1px solid;
}
.errorContainer {
  position: absolute;
  top: 9px;
  right: 30px;
}
.input:focus {
  outline: none;
}
.formContainer{
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 100%;
}
.formContainer::-webkit-scrollbar{
    width: 0;
    background-color: transparent;
    display: none;
}

.eventTitleContainer {
  display: inline-flex;
  padding: 20px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 47vw;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
overflow-y: scroll;
height: 400px;
}


.buttonContainer {
  display: flex;
  gap: 20px;
}


.urlInput {
  width: 100%;
  height: 40px;
  border: 0;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.urlInput:focus {
  outline: none;
}

@media (max-width: 768px) {
  .newEventContainerMobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px;
    height: calc(100svh - 220px);
  }
  .back {
    font-size: 12px;
  }
  .title {
    font-size: 14px;
  }
  .subTitle {
    color: var(--Text-dark, #393d63);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .cover {
    height: 180px;
  }
  .image {
    height: 180px;
  }
  .uploadContainer {
    padding: 12px 8px;
    gap: 5px;
    width: 90px;
  }
  .uploadText {
    font-size: 12px;
    width: 90px;
  }
  .plus {
    width: 24px;
    height: 24px;
  }
  .openTitle {
    font-size: 16px;
  }
  .text {
    font-size: 14px;
  }
  .subtext {
    font-size: 12px;
  }
  .openUploadContainer {
    padding: 10px;
    width: auto;
  }

  .hiddenInput {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .customFileInput {
    color: var(--text-light, #65677d);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    border: 1px solid var(--text-light, #65677d);
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 70px;
  }

 
  .eventTitleContainer {
    padding: 10px;
width: auto;
height: 500px;

  }
  .input {
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    gap: 6px;
  }

 .formContainer{
    overflow-y: scroll;
 }
  .createButton {
    display: none;
  }
  .createButtonMobile {
    display: inline-flex;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--light-purple, #61487c);
    color: #fff;
    width: 170px;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0;
    margin: 10px;
  }





 .mobileOptions{
    display: flex;
    gap: 10px;
    height: 50px;
 }
.option{
    display: inline-flex;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
 
    color: #393d63;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0;
    margin: 10px;}
.highlight{
    display: inline-flex;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--light-purple, #61487c);
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0;
    margin: 10px;}
  .scrollable{
    flex-direction: column;
    height: 500px;

  }
  .inputContainer{
    width: calc(100vw - 80px);
  }
}
