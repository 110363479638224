.container {
  position: fixed;
  bottom: 20px;
  right: 30px;
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background:white;
  opacity: 0;
  transition: opacity 1s ease;
  cursor: pointer;
  z-index: 100;
}
.appear {
  opacity: 1;
}
