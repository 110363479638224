.mainContainer {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
  padding: 80px 200px;
}
.image {
  width: 100%;
}
.videoMobile {
  display: none;
  width: 100%;
}
.singleCard {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;
  position: relative;
  padding: 20px 20px 20px 95px;
  height: 100%;
  min-height: 95px;
  max-height: 120px;
}
.cards {
  height: 283px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  height: auto;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 70px;
}
.iconContainer {
  width: 95px;
  height: 95px;
  flex-shrink: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(10px);

  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.iconContainer > svg {
  width: 40px;
  height: 44px;
}
.cardTitle {
  color: #393D63;

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0;
}
.cardText {
  color: rgba(57, 61, 99, 0.75);

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.cardContent {
  display: flex;
  flex-direction: column;
}
.title {
  display: none;
}
.iconContainerMobile{
  display: none;
}
.mobileImage{
  display: none;
}
@media (max-width: 1150px) {
  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cards {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    height: 100%;
    gap: 20px;
  }
  .cardText {
    font-size: 12px;
  }
  .cardTitle {
    font-size: 14px;
  }
  .singleCard {
    padding: 16px 12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 0.5px 1px 1px 0px #FFF inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding-left: 50px;
    height: 100%;
    min-height: 60px;
    max-height: 120px;
  }
  .cardContent {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .iconContainer{
    width: 50px;
    height: 50px;
    left: -15px;
  }
  .iconContainer > svg {
    width: 30px;
    height: 30px;
  }

  .cardsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 0px;
  }
  .image{
    display: none;
  }
  .mobileImage{
    display: flex;
  }
}
@media (max-width: 768px) {
  .mainContainer {
    min-width: 0;
  }
  .container {
    padding: 40px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    display: none
  }
  .cards {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    height: 100%;
  }
  .cardText {
    font-size: 12px;
  }
  .cardTitle {
    font-size: 14px;
  }
  .singleCard {
    padding: 16px 12px;
    border-radius: 10px;
background: rgba(255, 255, 255, 0.50);
box-shadow: 0.5px 1px 1px 0px #FFF inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
padding-left: 20px;
  }
  .cardContent {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .iconContainer {
 display: none;
  }

  .iconContainerMobile{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 0.5px 1px 1px 0px #fff inset;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    padding: 8px;

  }
  .iconContainerMobile > svg{
    width: 24px;
height: 24px;
  }
  .mobileImage{
    display: flex;
  }
}
@media (max-height: 560px){
  .cardTitle{font-size: 12px;}
  .cardText{
    font-size: 13px;
  }
}
