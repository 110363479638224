.container {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
  height: calc(100vh - 80px);
  flex-direction: column;
}

.coverWrapper {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  height: 220px;
  width: 100%;
  position: relative;
}

.coverImage {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 120px;
  width: 100%;
  object-fit: cover;
}

.imageCover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(
    180deg,
    rgba(248, 235, 197, 0.5) 0%,
    rgba(250, 209, 203, 0.5) 33%,
    rgba(237, 196, 228, 0.5) 67%,
    rgba(221, 202, 241, 0.5) 100%
  );
}

.userInfo {
  display: flex;
  justify-content: space-between;
}

.userDetails {
  padding-left: 200px;
  position: relative;
}

.scoreContainer {
  padding-right: 10px;
}

.userName {
  color: #393d63;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.userOccupation {
  color: rgba(57, 61, 99, 0.75);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.userLocations {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
  height: 25px;
  margin-bottom: 5px;
}

.iconContainer {
  display: flex;
  width: 160px;
  height: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--dashbord-primary);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: 20px;
}

.scoreContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.score {
  color: #fbbf31;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.scoreHighlighted {
  color: rgba(251, 191, 49, 0.5);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.button {
  display: flex;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--light-purple, #61487c);
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  cursor: pointer;
}

.button:hover {
  background: var(--purple, #432c5b);
}

.aboutWrapper {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  display: flex;
  padding: 20px;
  width: calc(100% - 40px);
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  height: calc(100% - 40px);
}

.infoIcon {
  position: absolute;
  top: -10px;
  right: 3px;
}

.infoBubble {
  display: flex;
  flex-direction: column;
  width: 260px;
  padding: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid rgba(251, 191, 49, 0.5);
  background: rgba(251, 191, 49, 0.5);
  backdrop-filter: blur(5px);
  position: absolute;
  left: -245px;
  top: -25px;
}

.bubbleText {
  color: var(--Text-dark, #393d63);
font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 3px;
}

.bubbleAddon {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid rgba(251, 191, 49, 0.5);
  position: absolute;
  right: -9px;
  top: 20px;
}

.title {
  color: #393d63;
font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.text {
  color: rgba(57, 61, 99, 0.75);
font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.editIcon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.profileEdit{
 margin:5px 0px; 
  display: none;
  cursor: pointer;
}
.addImage{

  display: flex;
  width: 160px;
  height: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #61487c95;
  backdrop-filter: blur(1px);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0px;
  display: none;
}
.iconContainer:hover .addImage{
  display: flex;
}
.userInfo:hover .profileEdit{
  display: flex;
}
.gridContainer {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 10px;
  padding-bottom: 20px;
  scroll-snap-type: y mandatory;
  width: 100%;
}
.gridContainer::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
}

.gridItem {
  flex: 1 1 100%;
  scroll-snap-align: start;

  transition: all 0.2s ease-in-out;
}

.gridItem.twoPerRow {
  flex: 1 1 45%;
}

.gridItem.shrink {
  flex: 1 1 45%;
}

.gridItem.expand {
  flex: 1 1 45%;
}
.experienceWrapper {
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.experienceActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plus {
  display: flex;
  width: 22px;
  height: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #432c5b;
  border-radius: 17.111px;
}

.componentsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.singleCard {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
}
.cardImage {
  width: 64px;
  height: 64px;
  border-radius: 10px;
  background: #d9d9d9;
}
.cardInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 70%;
}
.cardTitle {
  color: #393d63;

font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-wrap: wrap;
}
.cardCompany {
  margin: 0;
  color: rgba(57, 61, 99, 0.75);
font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.cardSubtext {
  margin: 0;
  color: rgba(57, 61, 99, 0.75);
font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: wrap;
}
.educationWrapper {
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.educationActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.skillsWrapper {
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.skill {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
}
.skillName {
  color: #393d63;

font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.subtext {
  color: #393d63;

font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.modalWrapper {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: rgba(211, 207, 207, 0.561);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.modal {
  display: inline-flex;
  padding: 20px 20px 20px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(25px);
  max-height: 400px;
  min-height: 100px;
}
.modal::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
.modalScroll {
  overflow: scroll;
  scroll-snap-type: y mandatory;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.modalScroll > * {
  scroll-snap-align: start;
}
.modalScroll::-webkit-scrollbar{
  width: 0;
  background-color: transparent;
}
.editAboutInput {
  width: 580px;
  height: 189px;
  border-radius: 10px;
  border: 1px solid var(--text-light, #65677d);
  background: #fff;
  padding: 20px;
  box-sizing: border-box; /* Ensures padding is included in the height calculation */
  vertical-align: top; /* Aligns the text at the top */
  line-height: 1.5; /* Adjust the line height as needed */
  overflow: hidden; /* Prevents content overflow */
  resize: none; /* Prevents resizing if the input is a textarea */
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalButtonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 10px 10px;
border-top: 0.5px solid var(--text-light, #65677D);
padding-top: 11px;
}
.modalButton {
  border-radius: 14px;
  background: var(--light-purple, #61487c);
  display: flex;
  width: 58px;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalRemoveButton{
  border-radius: 14px;
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  color: var(--purple, #432C5B);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalButton:hover{
  border-radius: 14px;
  background: var(--purple, #432C5B);
  color: #FFF;
}
.modalRemoveButton:hover{
  border-radius: 14px;
background: var(--light-purple, #61487C);
color: #FFF;
}
.modalRemoveButton:active{
  border-radius: 14px;
background: var(--purple, #432C5B);
color: #FFF;
}
.selectedOption {
  display: flex;
  width: 580px;
  height: 20px;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
  position: relative;
  color: var(--text-light, #65677D);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.selectedOption.disabled {
  background: rgb(140 140 140/0.2);
}
.languageEdit {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.selectedOption.dateinput {
  width: 100px;
}
.dropDown {
  position: relative;
}
.options {
  flex-direction: column;
  width: 580px;
  align-items: right;
  background: #fff;
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
  margin: 0;
  overflow-y: scroll;
  height: 140px;
  z-index: 100;
}
.options.dateinput {
  width: 100px;
}

.selectedOption.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0.5px solid var(--text-light, #65677d);
}
.option {
  list-style: none;
  height: 45px;
  color: #65677d;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}
.option:hover {
  color: #65677d;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.input {
  display: flex;
  width: 580px;
  height: 20px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 19px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 0;
}
.inputTitle {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.experienceBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.checkbox {
  display: flex;
  gap: 10px;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-items: center;
}
.emptyCheckBox{
  width: 20px;
  height: 20px;
  background-color: #F9F9F9;
  border-radius: 5px;
  border: 1px solid transparent;
display: flex;
justify-content: center;
align-items: center;
}
.emptyCheckBox:hover{
  border: 0.5px solid #61487C;
}
.experienceEdit {
  display: flex;
  justify-content: space-between;
}
.dateInput {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.dateDropdowns {
  display: flex;
  gap: 10px;
}
.dateBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.edit {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}
.skill:hover .edit {
  display: flex;
}
.singleCard:hover .edit {
  display: flex;
}

/*ADD COMPONENT */

.wrapper {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
}
.components {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.component {
  display: flex;
  width: 52.5px;
  height: 52.5px;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  border: 2px solid transparent; 
}
.componentTitle {
  color: rgba(57, 61, 99, 0.75);

  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.loading{
  display: flex;
position: absolute;
top: 50%;
left: 50%;
transform: translate(50% -50%);
}
.emptyProfile{
  color: #393d63;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto;



}
.existingComponent {
  background-color: #f0f0f0; /* Example style: change background color */
  border: 2px solid #ccc; /* Example style: add border */
}
.componentHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.profiles{
  display: flex;
padding: 6px 6px;
align-items: flex-start;
gap: 10px;
border-radius: 20px;
background: rgba(255, 255, 255, 0.80);
box-shadow: 0px 1px 1px 0px #FFF inset, 1px 0px 1px 0px #FFF inset;
width: 96%;
}
.profile{
  color: rgba(57, 61, 99, 0.50);

font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
padding: 5px 5px;
justify-content: center;
align-items: center;
gap: 10px;
text-align: center;
width: 100%;
}
.selected{
  color: #FFF;

font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 35px;
background: var(--light-purple, #61487C);
box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
display: flex;
padding: 5px 10px;
justify-content: center;
align-items: center;
gap: 10px;
text-align: center;

}
.profileList{
  width: 100%;
}
.profileCard{
  border-radius: 10px;
background: rgba(67, 44, 91, 0.90);

box-shadow: 0.5px 1px 1px 0px #FFF inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(10px);
width: 92%;

display: flex;
padding: 10px;
flex-direction: column;

gap: 10px;
}
.profileCardTitle{
  color: #FFF;

font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0;
}
.profileCardSubtitle{
  color: rgba(255, 255, 255, 0.75);

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.suggestions{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.suggestionCard{
  color: var(--text-light, #65677D);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 10px;
background: rgba(255, 255, 255, 0.50);

box-shadow: 0.5px 1px 1px 0px #FFF inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(10px);
display: flex;
padding: 10px;
flex-direction: column;
align-items: flex-end;
gap: 10px;
}
.suggestionButtonContainer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.quickChat{
  width: 100%;
}
.mobileClose{
  display: none;
}
.expandMenu{
  display: none;
}
@media (max-width: 768px){
  .leftSide{
    display:none
  }
  .container{
    padding: 10px;
    background: none;
    height: calc(100vh - 210px);
  }
  .coverWrapper{
    height: 150px;
  }
.coverImage{
  height: 70px;
}
.imageCover{
  height: 70px;
}
.iconContainer{
  width: 60px;
  height: 60px;
  left: 10px;
  top: 40px;
}
.userDetails{
  padding-left: 75px;
}
.userName{
  font-size: 14px;
}
.userLocations{
  font-size: 12px;
}
.userOccupation{
  font-size: 12px;
}
.score{
  font-size: 8px;
}
.scoreContainer{
  gap: 0;
}
.modalWrapper{
  top: 0;
  height: 100vh;
  background: var(--Gradient-Color, linear-gradient(180deg, #F8EBC5 0%, #FAD1CB 33%, #EDC4E4 67%, #DDCAF1 100%));
flex-direction: column;
justify-content: flex-start;
display: flex;}
.modal{
  width: 85vw;
}
.input{
  width: calc(100% - 40px);
}
.modalScroll{
  width: 100%;
}
.date{
  flex-direction: column;
  gap :10px;
}
.addImage{
  width: 60px;
  height: 60px;
}
.mobileClose{
  display: flex;
width: 100%;
padding: 20px;
margin-left: 20px
}
.closeButton{
  display: none;
}
.modalMobileButtonContainer{
  display: flex;
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--text-light, #65677D);
background: rgba(255, 255, 255, 0.80);
width: 100%;
height: 50px;
align-items: center;
justify-content: space-between;
margin: 0 20px;
}
.modalMobileButton{
  height: 30px;
  margin: 0 20px;
  border-radius: 14px;
  background: var(--light-purple, #61487c);
  display: flex;
  width: 58px;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalButtonContainer{
  display: none;
}
.expandMenu{
  display: flex;
  position: absolute;
  left: 0;
  top:50%;
  width: 20px;
height: 50px;
border-radius:  0px 10px 10px 0px;
background: rgba(255, 255, 255, 0.5);
box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
justify-content: center;
align-items: center;
}
.sideModal{
  display: flex;
  position: absolute;
  top: 0;
  height: calc(100vh - 130px);
  background: linear-gradient(180deg, #F8EBC5 0%, #FAD1CB 33%, #EDC4E4 67%, #DDCAF1 100%);box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
padding: 10px;
flex-direction: column;
gap: 10px;
}
.expandMenuBack{
  display: flex;
  position: absolute;
  right: 0;
  top:50%;
  width: 20px;
height: 50px;
border-radius:  10px 0px 0px 10px;
background: rgba(255, 255, 255, 0.5);
box-shadow: 0.5px 1px 1px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
justify-content: center;
align-items: center;
}
}