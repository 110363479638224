.container {
    display: flex;
    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.28) 19.57%,
      rgba(255, 255, 255, 0.4) 100%
    );
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;
    backdrop-filter: blur(2px);
    flex-direction: column;
    height: calc(100vh - 180px);
    max-width: 340px;
    padding: 10px 20px;
    gap: 10px;

}
  .userInfoContainer{
    display: flex;
flex-direction: column;
align-items: center;
gap: 5px;
margin-top: 35px;
padding: 20px;
padding-bottom: 20px;
border-radius: 10px;
background: rgba(255, 255, 255, 0.50);
box-shadow: 0.5px 1px 1px 0px #FFF inset;
position: relative;
  }
  .userImageContainer{
    width: 80px;
height: 80px;
border-radius: 100px;
border: 3px solid rgba(255, 255, 255, 0.50);
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: -40px;
  }
  .image{
    width: 80px;
    height: 80px;
  }
  .userInfo{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .userName{
    color: var(--Text-dark, #393D63);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0;
  }
  .iconContainer{
    background: var(--dashbord-primary);
    width: 80px;
    height: 80px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userProf{
    color: var(--text-light, #65677D);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
  }
  .profiles{
    display: flex;
padding: 6px 6px;
align-items: flex-start;
gap: 10px;
border-radius: 20px;
background: rgba(255, 255, 255, 0.80);
box-shadow: 0px 1px 1px 0px #FFF inset, 1px 0px 1px 0px #FFF inset;
  }
  .profile{
    color: rgba(57, 61, 99, 0.50);

font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
padding: 5px 5px;
justify-content: center;
align-items: center;
gap: 10px;
text-align: center;
  }
  .selected{
    color: #FFF;

font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 35px;
background: var(--light-purple, #61487C);
box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
display: flex;
padding: 5px 10px;
justify-content: center;
align-items: center;
gap: 10px;
text-align: center;

  }
  .statisticsContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
.subContainer{
  display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap ;
  }
  .singleCardStatistics{
    display: flex;
height: 50px;
width: 100%;
max-width: 125px;
min-width: 60px;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 2px;
border-radius: 10px;
background: rgba(255, 255, 255, 0.50);
box-shadow: 0.5px 1px 1px 0px #FFF inset;
  }
  .singleCardStatisticsNumber{
    color: var(--Text-dark, #393D63);
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0;
height: 20px;
width: 100%;
text-align: center;
  }
  .singleCardStatisticsSubTitle{
    color: var(--text-light, #65677D);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
  }
  .choicesTitle{
    color: var(--Text-dark, #393D63);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0;
  }
  .singleCard {
    /* Your existing styles */
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 0.5px 1px 1px 0px #FFF inset;
    color: var(--text-light, #65677D);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: height 0.3s ; /* Apply transition to height */
    scroll-snap-align: start;
}

/* Styles for the open card */
.open.singleCard{
    height: 100%; /* Set height to auto when open */
}

/* Styles for the closed card */
.closed.singleCard {
    height: 60px; /* Set height to 60px when closed */
}


  .highlighted{
    color: var(--Text-dark, #393D63);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .choicesContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
 overflow-y:scroll;
  }
  .cardsContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  }
  .cardsContainer::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
  .choicesContainer::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
  .scroll{
    color: var(--text-light, #65677D);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
margin: 10px 10px;
  }
  .button{
    display: inline-flex;
padding: 5px 15px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
border: 0;
background: none;
color: #393D63;

font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: auto;
cursor: pointer;
display: none;
  }
  .button:hover{
    background: var(--light-purple, #61487C);
    color: #FFF;
  }
  .button:active{
background: var(--purple, #432C5B);
  }
  .show{
    display: flex;
  }
.sliderContainer{
  height: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 0.5px 1px 1px 0px #FFF inset;
  position: relative;
  justify-content: center;
  align-items: center;
}
.slide{
  justify-content: center;
}
.graphs{
  display: flex;
  flex-direction: column;
  justify-content: center;
gap: 5px;
}
.arrowLeft{
  position: absolute;
}
.arrowRight{
  position: absolute;
  right: 30px;
}
.graph{
  display: flex;
  flex-direction: column;
  justify-content: center;
align-items: center;
gap: 5px;
}
.statistics{
  display: flex;
padding: 10px;
flex-direction: column;
gap: 10px;
  border-radius: 10px;
background: rgba(255, 255, 255, 0.50);
box-shadow: 0.5px 1px 1px 0px #FFF inset;

}
.subtitle{
  color: var(--Text-dark, #393D63);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0;
text-align: left;
}
.circularBar{
  height: 60px;
  width: 60px;
  align-items: center;
  position: relative;
}

.barPercentage{
  color: var(--text-light, #65677D);
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: normal;
position: absolute;
left: 20px;
}
.mobileBar{
  font-size: 18px;
  left: 30px;
  top: 20px;
}
.subText{
  color: var(--text-light, #65677D);
text-align: center;
font-family: Poppins;
font-size: 8px;
width: 40px;
font-style: normal;
font-weight: 300;
line-height: normal;
position: absolute;
top: 15px;
left: 10px;
}
.mobileBarSubText{
  font-size: 13px;
  top: 45px;
  left: 20px;
}
.smallText{
  margin: 0;
  color: var(--text-light, #65677D);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.smallNumber{
  margin: 0;
  color: var(--text-light, #65677D);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 30px;
}
.display{
  display: flex;
  
}.hide{
  display: none;
}
.graph{
  gap: 0px;
}
@media(width <= 1290px){
  .subContainer{

      flex-wrap: none ;
      height: 150px;
      overflow: scroll;
     scroll-snap-type: y mandatory;
     scroll-snap-align: start;
     overflow-x: hidden;
     overflow-y: scroll;
     scrollbar-width: thin;
    }
    .subContainer::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      display: none;
    }
    .singleCardStatistics{
      scroll-snap-align: start;
      width: 100%;
      max-width: none;
    }
}
@media (min-height: 730px){
.container{
  gap: 20px;
}
.userImageContainer{
  width: 100px;
  height: 100px;
}
.image{
  width: 100px;
  height: 100px;
}
.userInfoContainer{
  margin-top: 60px;
  padding-top: 60px;
}
.singleCardStatistics{
  height: 75px;
}
.singleCardStatisticsNumber{
  font-size: 30px;
  height: 40px;
}
.singleCardStatisticsSubTitle{
font-size: 12px;
}
.circularBar{
  width: 100px;
  height: 100px;
}

.graphs{
  gap: 20px;
}
.graph{
  gap: 12px;
}
.subText{
  top: 30px;
  left: 20px;
}
.barPercentage{
  left: 30px;
  top: 10px;
}
}