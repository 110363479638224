.container {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(2px);
  flex-direction: column;
  justify-content: space-between;
  max-width: 680px;
  min-width: 250px;
  padding: 20px;
  overflow: hidden;
  height: 100%;
}
.bigInput{
  width: 100%;
}
.title {
  color: var(--dashbord-headers);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.card {
  display: flex;
  height: 36px;
  padding: 10px;
  cursor: pointer;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 2px 0px #fff inset;
}
.card:hover {
  background: rgba(255, 255, 255, 0.9);
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cardTitle {
  color: var(--dashbord-headers);
  margin: 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
}
.input {
  display: flex;
  height: 38px;
  padding: 0px 10px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px;
  background: var(--white);
  border: 0;
  box-shadow: 0.5px 1px 2px 0px rgba(0, 0, 0, 0.1) inset;
}
.mobileButton {
  display: none;
}

.mobilePlus {
  display: none;
}
.mobileInput {
  display: none;
}
@media (max-width: 768px) {
  .container {
    height: 48px;
    border-radius: 10px 10px 0px 0px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0.5px 1px 1px 0px #fff inset;
    backdrop-filter: none;
    position: fixed;
    bottom: 60px;
    left: 0;
    width: 100vw;
    display: none;
  }
  .title {
    display: none;
  }
  .cardsContainer {
    display: none;
  }
  .inputContainer {
    margin: 10px;
    gap: 10px;
  }
  .mobileButton {
    display: flex;
    padding: 5px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background: var(--dashbord-primary);
    color: var(--white);
    height: 28px;
    font-size: 12px;
    border: 0;
  }
  .input {
    height: 28px;
  }
  .mobilePlus {
    display: flex;
  }
  .mobileInput {
    display: flex;
  }
}
/*Big Chat*/
.bigContainer {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(2px);
  flex-direction: column;
  overflow-y: auto;
  max-width: 680px;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  height: 100%;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.singleCard {
  display: flex;
  height: 36px;
  padding: 10px;
  width: 255px;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 2px 0px #fff inset;
  color: #393d63;
  align-self: stretch;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contentTitle {
  color: #393d63;
  margin: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contentSubTitle {
  color: #65677d;
  margin: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 768px) {
  .bigContainer {
    backdrop-filter: none;
    height: calc(100vh - 300px);
    min-width: 200px;
    max-width: none;
    height: 100%;
  }
  .icon > svg {
    width: 30px;
  }
  .contentSubTitle {
    width: 270px;
  }
  .singleCard {
    width: auto;
    height: auto;
  }
  .bigInput {
    display: none;
  }
}

.chatInterfaceContainer {
  display: flex;


flex: 1;
max-height: 60vh;
  flex-direction: column;
  max-width: 680px;
  overflow-y: scroll;

  border-radius: 0px;
  background: none;
  box-shadow: none;
  backdrop-filter: none;
  padding: 0px;
}
.chatInterfaceContainer.chatInterfaceContainerBig{
  border-radius: 0px;
  background: none;
  box-shadow: none;
  backdrop-filter: none;
  padding: 0px;
}
.scrollChat{
  overflow-y: scroll;
  max-height: 65vh;
}
.scrollChat::-webkit-scrollbar{
  width: 0;
  background-color: transparent;
  display: none;
}
.chatInterfaceContainerBig{
  display: flex;

justify-content: space-between;
  flex: 1;
    flex-direction: column;
    max-width: 680px;

    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.28) 19.57%,
      rgba(255, 255, 255, 0.4) 100%
    );
    box-shadow: 0.5px 1px 1px 0px #fff inset;
    backdrop-filter: blur(2px);
    padding: 20px;
}
.chatInterfaceContainer::-webkit-scrollbar{
  width: 0;
  background-color: transparent;
}
.chatInterfaceContainerSmall {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 680px;
  min-width: 250px;
  overflow: hidden;
  background: none;
  box-shadow: none;
  backdrop-filter: none;
  padding: 0;
  border-radius: 0;
}

.request {
  color: #393d63;
  width: 188px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-flex;
  padding: 10px;
  justify-content: left;
  align-items: center;
  border-radius: 10px 10px 0px 10px;
  background: #fff;
  box-shadow: 0.5px 1px 2px 0px #fff inset;
  margin-top: 20px;
  margin-left: auto;
}
.response {
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 0px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0.5px 1px 2px 0px #fff inset;
  color: #65677d;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 188px;
  margin-top: 20px;
}
@keyframes ellipsis {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
  100% {
    content: '.';
  }
}

.responsez::after {
  content: '';
  display: inline-block;
  text-align: left;
  animation: ellipsis 1s steps(4, end) infinite;
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 0px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0.5px 1px 2px 0px #fff inset;
  color: #65677d;
  font-family: Poppins;
  font-size: 30px;
  height: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .chatInterfaceContainer {
    backdrop-filter: none;
    overflow: hidden;
    height: calc(100vh - 200px);
    width: 92vw;
    gap: 20px;
    background: 0;
    box-shadow: none;
  }
  .chatinput {
    display: none;
  }
  .request {
    color: #393d63;
    width: 188px;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-flex;
    padding: 10px;
    justify-content: left;
    align-items: center;
    border-radius: 10px 10px 0px 10px;
    background: #fff;
    box-shadow: 0.5px 1px 2px 0px #fff inset;
    margin-top: 20px;
    margin-left: auto;
  }
  .response {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 0px;
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0.5px 1px 2px 0px #fff inset;
    color: #65677d;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 188px;
  }
}

@media (max-height: 560px) {
  .container {
    padding-top: 10px;
  }
  .title {
    margin: 0 0 20px 0;
  }
}

.dropZone {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 0.5px 1px 1px 0px #FFF inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.dropZoneText {
  color: #4d4d4d;

  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
