.welcomeContainer {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 60.6%,
    #f8ebc5 82.1%,
    #fad1cb 88.6%,
    #edc4e4 94.1%,
    #ddcaf1 100%
  );
  backdrop-filter: blur(10px);
  border-radius: 10px;
  overflow: auto;
  height: 100%;
  width: 100%;
  position: relative;

}
.welcomeContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}
.steps {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.mainTitle {
  color: #432c5b;

  font-family: Poppins;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subTitle {
  color: #432c5b;

  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-align: center;
}
.firstStep {
  position: relative;
  margin-right: auto;
  margin-left: 20px;

}
.firstStepArrow {
  position: relative;
  top: -150px;
  right: -500px;
}
.secondStepAroow {
  position: relative;
  top: -150px;
  left: -250px;
}
.secondStep {
  position: relative;
  margin-left: auto;
  margin-right: 20px;
}
.thirdStep {
  position: relative;
  margin-right: auto;
  margin-left: 20px;
}
.thirdStepArrow {
  position: relative;
  top: -50px;
  right: -500px;
}
.forthStep {
  position: relative;
  margin-left: auto;
  margin-right: 20px;
}
.main {
position: relative;
  width: 100%;
}
.mainImageTitle {
  color: #432c5b;

  font-family: Poppins;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  text-align: center;
  top: 250px;
}
.button {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 50px;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #fff;
  color: #393d63;
  border: 0;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.closeButton {
  position: sticky;
margin-left: auto;
  top: 20px;
  z-index: 50;
  width: 50px;
}
@media (max-width: 768px) {
  .welcomeContainerMobile {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 60.6%,
      #f8ebc5 82.1%,
      #fad1cb 88.6%,
      #edc4e4 94.1%,
      #ddcaf1 100%
    );
    backdrop-filter: blur(10px);
    height: 75svh;
    overflow: hidden;
    overflow-y: auto;
  }
  .closeButtonMobile {
    position: fixed;
    right: 0px;
    z-index: 50;
  }
  .mainTitleMobile {
    color: #432c5b;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding-top: 10px;
  }
  .subTitleMobile {
    color: #432c5b;

    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    text-align: center;
  }
  .stepsMobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .firstStepMobile {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .firstStepArrowMobile {
    position: absolute;
    right: -25px;
    bottom: 0;
    transform: rotate(50deg);
  }
  .secondStepMobile {
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .secondStepArrowMobile {
    position: absolute;
    left: -85px;
    bottom: 0;
    transform: rotate(-50deg);
  }
  .thirdStepMobile {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .thirdStepArrowMobile {
    position: absolute;
    right: -70px;
    bottom: -20px;
    transform: rotate(50deg);
  }
  .forthStepMobile {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .mainMobile {
    position: relative;
  }
  .buttonMobile {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    padding: 5px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #fff;
    color: #393d63;
    border: 0;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mainImageTitleMobile {
    color: #432c5b;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
  .welcomeContainerMobile::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
}
