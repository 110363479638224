.mainContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  overflow: scroll;
}
.container {
  display: flex;
  width: 500px;
  padding: 20px 40px;
  min-height: 300px;
  max-height: 650px;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  border-radius: 20px;
  background: var(--white);
  position: relative;
  overflow: scroll;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;

}
.container::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}
.title {
  color: var(--dark-purple, #251436);
font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.welcomeTitle {
  color: var(--black);
font-family: Poppins;
  font-size: 24px;
  margin: 0;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  
}
.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.errorIconContainer {
  position: absolute;
  top: 7px;
  right: 15px;
}
.inputFieldError {
  display: flex;
  padding: 0px 20px;
  height: 35px;
  align-items: center;
  border-radius: 25px;
  background: var(--input-bg);
  border: var(--input-error-red) 1px solid;
  font-size: 16px;
font-family: Poppins;
}
.inputFieldError:focus {
  outline: var(--input-error-red) 1px solid;
}

.inputField {
  display: flex;
  padding: 0px 20px;
  height: 35px;
  align-items: center;
  border-radius: 25px;
  background: var(--input-bg);
  border: transparent 1px solid;
  color: var(--dark-purple, #251436);
font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.button {
  display: flex;
  height: 35px;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #251436;
  border: 0;
  color: white;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
}
.button:hover{
  background-color: #61487C;
}
.signUpText {
  text-align: center;
  margin: 0;
  color: var(--dark-purple, #251436);
font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.highlight {
  color: #9E7AC3;

font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.forgotPassword {
  margin-left: 20px;
  width: 150px;
  text-decoration: underline;
}

.forgotPassword:link,
.forgotPassword:visited,
.forgotPassword:hover,
.forgotPassword:active {
  color: var(--black);
}

.errorMessage {
  color: var(--input-error-red);
  margin: 5px;
  margin-left: 20px;
font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  height: 15px;
}

.showPassword {
  position: absolute;
  right: 45px;
  top: 8px;
}
.closeBubble{
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.divider {
  position: relative;
  margin: 20px 0px;
  width: 100%;
}
.hr {
  height: 0.5px;
  flex: 1 0 0;
  background: var(--input-placeholder);
}
.dividerText {
  display: flex;
  padding: 0 10px;
  background-color: var(--white);
  position: absolute;
  top: -15px;
  right: 50%;
  transform: translateX(50%);
  color: var(--black);
font-family: Poppins;
  font-size: 10px;
}
.mobileButton {
  display: flex;
  height: 35px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 100px;
  background: var(--input-bg);
cursor: pointer;
  color: var(--black);
  font-family: Poppins;
  font-size: 16px;
  border: 0;
}
.mobileButton:hover{
  background-color: #61487C;
  color: white;
}
.loginIcon {
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .container {
    display: flex;
    width: 80vw;
    padding: 40px 20px 20px 20px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
  .welcomeTitle {
    display: contents;
  }
  .title {
    color: var(--dark-purple, #251436);
  font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .inputField {
    display: flex;
    height: 35px;
    padding: 10px 15px;
    align-items: center;
    flex: 1 0 0;
    color: var(--input-placeholder);
  font-family: Poppins;
    font-size: 10px;
    width: auto;
  }
  .form {
    width: 100%;
  }
  .inputContainer {
    width: 100%;
    display: flex;
  }
  .inputFieldError {
    width: auto;
    align-items: center;
    border-radius: 25px;
    background: var(--input-bg);
    border: var(--input-error-red) 1px solid;
    display: flex;
    height: 35px;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: var(--input-placeholder);
  font-family: Poppins;
    font-size: 10px;
    width: auto;
  }
  .errorIconContainer {
    position: absolute;
    top: 5px;
    right: 15px;
  }
  .errorMessage {
    color: var(--input-error-red);
  font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
  }
  .forgotPassword {
    color: var(--input-placeholder);
  font-family: Poppins;
    font-size: 10px;
  }
  .button {
    display: flex;
    height: 35px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
    background: #251436;
    margin: 0;
    margin-top: 20px;
    color: white;
    font-family: Poppins;
    font-size: 12px;
  }
  .button:active {
    background-color: #61487C;
    color: var(--white);
  }
  .loginIcon {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divider {
    position: relative;
    margin: 20px 0px;
  }
  .hr {
    height: 0.5px;
    flex: 1 0 0;
    background: var(--input-placeholder);
  }
  .dividerText {
    display: flex;
    padding: 0 10px;
    background-color: var(--white);
    position: absolute;
    top: -15px;
    right: 50%;
    transform: translateX(50%);
    color: var(--black);
  font-family: Poppins;
    font-size: 10px;
  }
  .signUpText {
    display: none;
  }
  .mobileButton {
    display: flex;
    height: 35px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
    background: var(--input-bg);

    color: var(--black);
    font-family: Poppins;
    font-size: 12px;
    border: 0;
  }
  .mobileButton:active {
    background-color: #61487C;
    color: var(--white);
  }
  .inputField:focus{
    outline: red;
  }
  .showPassword {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}
@media (max-height: 560px){
  .container{
    gap: 0;
    overflow: scroll;

  }
  .title{
    font-size: 20px;
  }
  .button,.mobileButton,.inputField,.inputFieldError{
    height: 30px;
  }
  .loginIcon{
    height: 200px;
  }
  .errorIconContainer{
    top: 4px;  }
}