.container {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  background-color: var(--black);

}
.form{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.dashWelcome::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}
.cover {
 width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  background-color: #251436;
  z-index: -1;
}
.stickyNav {
  position: sticky;
  top: 0;
  background-color:#251436ae;
  z-index: 55;
}
.subContainer {
  margin: 0 20px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: var(--white);
}
.menuContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 100%;
}
.menuItems {
  display: flex;
  flex-direction: row;
  width: 716px;
  padding: 0px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}
.menuItemsVisible {
  opacity: 1;
  transform: translateX(0);
}
.singleMenuItem {
  color: var(--white);
  font-size: 18px;
  cursor: pointer;
}
.singleMenuItem:hover {
  color: #61487C;
}
.menuButton {
  width: 125px;
  height: 35px;
  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--white);
  color: black;
  font-size: 15px;
}
.menuButton:hover {
  width: 125px;
  height: 35px;
  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #61487C  ;
  color: var(--white);
  font-size: 15px;
}
.mobileMenu {
  display: none;
}
.mobileHamburger {
  display: none;
}
.chimpIcon:hover > svg{
  color: #9E7AC3;
}
.menuBackground{
  height: 100vh;
}
@media (max-width: 768px) {
  .hamburger{
    z-index: 100;
  }
  .container {
    height: 60px;
    background-color:#251436 ;
    min-width: 0;
  }
  .menuItems {
    display: none;
  }
  .menuItemsVisible {
    display: none;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    right: -1px;
    width: 200px;
    background-color: var(--white);
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--black);
    padding: 40px 0px 60px 0px;
    gap: 20px;
    z-index: 99;
    padding-left: 20px;
  }
.HamburgerMenuMobile{
  display: flex;
  justify-content: right;
  margin-right: 20px;
}
  .mobileMenu.open {
    animation: slideIn 0.3s forwards;
  }

  .mobileMenu.closed {
    animation: slideOut 0.3s forwards;
  }
  .mobileMenuItems {
    color: var(--white);
    height: 27px;
    font-size: 18px;
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }

  .mobileMenuItems:active {
    color: #61487C;
  }
  .mobileMenuItems:hover {
    color: #61487C;
  }
  .menuBackground {
    background-color: var(--black-transparent);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

}
