.chatBigInputContainer {
  display: flex;
  gap: 10px;
  width: auto;
  position: relative;
}
.chatBiginput {
  display: flex;
  height: 36px;
  padding: 0px 10px;
  align-items: center;
  border-radius: 10px;
  flex: 1; background: #fff;
  box-shadow: 0.5px 1px 2px 0px rgba(0, 0, 0, 0.1) inset;
  color: #65677d;
  border: 0;
font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}
.mobilePlus {
  display: none;
}
.counter {
  border-radius: 10px;
  background: #432c5b;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 50px;
  top: 9px;
  color: #fff;
  text-align: center;
font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.send {
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12.857px;
  background: #432c5b;
  border: 0;
}
@media (max-width: 768px) {
  .chatBigInputContainer {
    position: fixed;
    bottom: 60px;
    left: 0;
    border-radius: 10px 10px 0px 0px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0.5px 1px 1px 0px #fff inset;
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    margin: 0;
    width: calc(100vw - 20px)
  }
  .counter {
    border-radius: 10px;
    background: #432c5b;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 15px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mobilePlus {
    display: flex;
  }
  .chatBiginput {
    height: 28px;
  }
  .send {
    height: 24px;
    width: 24px;
    border-radius: 9px;
  }

}
