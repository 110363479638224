.dashHeader {
  display: flex;
  height: 60px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
}

.icon {
  cursor: pointer;
  padding-left: 40px;
}
.searchComponent {
  width: 100%;
  display: flex;
  justify-content: center;
}
.iconContainer {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--dashbord-primary);
  cursor: pointer;
}
.menuItemsContainer {
  position: absolute;
  right: 40px;
  display: flex;
  gap: 25px;
}
.menuItem {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileMenuContainer {
  display: none;
}
.mobileIconContainer {
  display: none;
}
.mobileMenu {
  display: none;
}
.searchContainer {
  margin: 20px;
  display: flex;
  height: 40px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0.5px 1px 2px 0px rgba(0, 0, 0, 0.1) inset;
  max-width: 1295px;
  min-width: 300px;
  width: 100%;
  margin-bottom: 0;
}
.searchInput {
  border: 0;
  height: 80%;
  background: #fcfcfc;
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 12px;
  flex: 1;
}
.searchInput:focus {
  outline: none;
}
.highlightedTab {
  color: var(--black);
}
.chatHeaderContainer {
  display: none;
}
.dashButton {
  display: flex;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #432c5b;
  color: #fff;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.dashButton:hover {
  background: #61487c;
}
.disabled {
  color: #0000001a;
}
.uploadContainer {
  border-radius: 10px;
  border: 1px dashed var(--light-purple, #61487c);
  background: rgba(142, 70, 221, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
}
.uploadText {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.uploadSubText {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.successfulMessage {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color:  #393d63;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  z-index: 20;
}

.successfulMessage.show {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0);
}

.successfulMessage.hide {
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -100%);
}
@media (max-width: 768px) {
  .menuItemsContainer {
    display: none;
  }
  .searchContainer {
    display: none;
  }
  .mobileMenuContainer {
    display: flex;
    width: 100%;
    gap: 10px;
  }
  .dashHeader {
    display: flex;
    background: none;
    padding: 0px 10px;
    gap: 10px;
    width: auto;
    justify-content: space-between;
  }
  .uploadText {
    font-size: 13px;
  }
  .uploadSubText{
    font-size: 13px;
  }
  .hidden {
    display: none;
  }
  .mobileIconContainer {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--dashbord-primary);
  }
  .searchContainerMobile {
    display: flex;
    height: 28px;
    padding: 0px 10px;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    background: #fcfcfc;
    box-shadow: 0.5px 1px 2px 0px rgba(0, 0, 0, 0.1) inset;
    flex: 1;
  }
  .searchInputMobile {
    border: 0;
    width: 100%;
    height: 80%;
    background: #fcfcfc;
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 10px;
    flex: 1;
  }
  .searchInputMobile:focus {
    outline: none;
  }
  .mobileMenu {
    display: flex;
    height: 60px;
    align-items: center;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .mobileMenuItem {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 3px solid transparent;
  }
  .highlighted {
    border-bottom: 3px solid #393d63;
  }
  .chatHeaderContainer {
    display: flex;
    gap: 10px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
  .icon {
    padding-left: 0px;
  }
  .hiddenHeader.dashHeader {
    display: none;
  }
  .dashButton {
    font-size: 10px;
    height: 20px;
    padding: 5px 10px;
  }
}
