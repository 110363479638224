.mainContainer {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(2px);
  flex-direction: column;
  overflow-y: auto;
  max-width: 680px;
  min-width: 350px;
  height: 100%;
  overflow: hidden;
}
.connectionsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.options {
  margin: 20px 20px 10px 20px;
  display: flex;
  gap: 10px;
}
.option {
  color: #393d63;

font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.notSelected {
  color: rgba(57, 61, 99, 0.5);

font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px;
}
.filter {
  cursor: pointer;
  display: flex;
  height: 15px;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  color: var(--dashbord-normalText);
font-family: Poppins;
  font-size: 12px;
}
.selectedFilter {
  background: var(--dashbord-primary);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 15px;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0.5px 1px 1px 0px #fff inset;
font-family: Poppins;
  font-size: 12px;
}
/* Used card START*/
.userCardContainer {
  display: flex;
  padding: 10px 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: #fff;
  box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.25),
    1px 0px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
}
.userInfo {
  width: 100%;
}
.userName {
  margin: 0;
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.userType {
  margin: 0;
  color: var(--light-purple, #61487c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.userOccupation {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.userLocation {
  display: flex;
  align-items: center;

  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.score {
  margin: 0;
  color: #fbbf31;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.scoreHighlight {
  color: rgba(251, 191, 49, 0.5);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.userDescription {
  color: #000;
  height: 35px;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
  overflow: hidden;
}
.imageContainer {
  height: 150px;
  }
  .userImage {
    height: 150px;
  }
.button1 {
  display: inline-flex;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  border: 0;
  background-color: white;
  color: #393d63;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 25px;
}
.button1:hover {
  color: #fff;
  background: var(--light-purple, #61487c);
}
.button1:active {
  color: #fff;
  background: var(--purple, #432c5b);
}
.button2 {
  display: inline-flex;
  padding: 2px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  border: 0;
  background: var(--light-purple, #61487c);
  color: #fff;
  height: 25px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.button2:hover {
  color: #fff;
  background: var(--purple, #432c5b);
}
.buttonNext {
  height: 48px;
  width: 48px;
  right: 20px;
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  background: white;
  border: 0;
  border-radius: 15px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
}
.buttonPrev {
  height: 48px;
  width: 48px;
  left: 20px;
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  background: white;
  border: 0;
  border-radius: 15px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
}
.infoIcon {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 2;
}
.infoBubble {
  display: flex;
  width: 146px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;

  background: rgba(251, 191, 49, 0.5);
  backdrop-filter: blur(2px);
  flex-direction: column;
  position: absolute;
  right: -160px;
  display: none;
}
.infoIcon:hover + .infoBubble {
  display: flex;
}
.bubbleAddon {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 8px solid rgba(251, 191, 49, 0.5);
  position: absolute;
  left: -4px;
  top: -2px;
  transform: rotate(45deg);
}
.bubbleText {
  color: var(--Text-dark, #393d63);
font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px;
}
/* User card END*/
/* Events  END*/
.eventsContainer {
  z-index: 10;
  overflow: hidden;
  height: 100%;
}
.filterContainer {
  margin: 5px 20px;
  display: flex;
  justify-content: space-between;
}
.title {
  color: #393d63;
  margin: 0px 20px 5px 20px;
  padding-top: 10px;
font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.scrollText {
  color: var(--dashbord-normalText);
  text-align: center;
  font-size: 12px;
  margin: 5px 20px 20px 20px;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
  height: 47%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.cardsContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}
.newEvent {
  color: var(--Text-dark, #393d63);
font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.plus {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 100px;
  background: #432c5b;
  color: white;
}
/* Events  END*/
@media (min-height: 730px) {
  .connectionsContainer {
    height: 50%;
  }
  .cardsContainer {
    height: 73%;
  }
}
