@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  cursor: default;
  min-width: 360px;

}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* AutocompleteStyles.css */
.pac-container {
  background-color: #fff !important;
  z-index: 1000 !important;
  width: 680px !important;
  position: absolute !important; 
  left: 50% !important;
  transform: translate(-50%) !important;
}

.pac-item {
  padding: 5px !important;
  cursor: pointer !important;
  color: var(--text-light, #65677d);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pac-item:hover {
  background-color: #f0f0f0 !important;
}

.pac-item .pac-icon {
  display: none !important;
}

.pac-item-query {
  font-weight: bold !important;
}

.pac-matched {
  color: #432c5b !important;
}
@media (max-width: 768px) {
  .pac-container {
    background-color: #fff !important;
    z-index: 1000 !important;
    width: 90% !important;
    position: absolute !important; 
    left: 50% !important;
    transform: translate(-50%) !important;
  }
  
}

:root {
  --black: #251436;
  --black-transparent: #000000bf;
  --white: #fff;
  --card-bg: #282828;
  --white-sub-text: #e8e8e8;
  --orange-light: #fbbf31;
  --orange-dark: #de9c00;
  --icon-bg-grey: #3f3f3f;
  --sub-text-grey: #2e2e2e;
  --input-grey: #cacaca;
  --input-bg: #f9f9f9;
  --input-placeholder: #292929;
  --input-error-red: #e50000;
  --dashbord-nearBlack: #33283f;
  --dashbord-primary: #432c5b;
  --dashbord-primaryHighlighted: #61487c;
  --dashbord-headers: #393d63;
  --dashbord-normalText: #65677d;
}
.disable-scroll {
  overflow: hidden;
}
.gradient {
  background: linear-gradient(
    180deg,
    #fdcdb8 0%,
    #ffc8e2 20.6%,
    #ffeeed 45.1%,
    #f8f4e5 61.6%,
    #fbd8f1 78.1%,
    #f6c5e8 100%
  );
}
.dashboard {
  background: linear-gradient(
    180deg,
    #f8ebc5 0%,
    #fad1cb 33%,
    #edc4e4 67%,
    #ddcaf1 100%
  );
  height: 100vh;
  overflow: auto;

}

.mainDashboard {
  margin: 20px;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.left,
.right {
  gap: 20px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 250px;
  height: auto;
  flex: 1;
}

.main {
  gap: 20px;
  display: flex;
  flex-direction: column;
  max-width: 680px;
  min-width: 500px;
  flex: 2;
  border-radius: 20px;
}
.link > .linkedin-solid-svg {
  display: inline-block;
}

.link:hover > .linkedin-solid-svg {
  display: none;
}

.link > .linkedin-gradient-svg {
  display: none;
}

.link:hover > .linkedin-gradient-svg {
  display: inline-block;
}

.link1 > .facebook-solid-svg {
  display: inline-block;
}

.link1:hover > .facebook-solid-svg {
  display: none;
}

.link1 > .facebook-grad-svg {
  display: none;
}

.link1:hover > .facebook-grad-svg {
  display: inline-block;
}

.link2 > .twitter-solid-svg {
  display: inline-block;
}

.link2:hover > .twitter-solid-svg {
  display: none;
}

.link2 > .twitter-grad-svg {
  display: none;
}

.link2:hover > .twitter-grad-svg {
  display: inline-block;
}
.chimpIcon {
  cursor: pointer;
}
.chimpIcon > .iconWhite {
  display: inline-block;
}

.chimpIcon:hover > .iconWhite {
  display: none;
}

.chimpIcon > .iconHover {
  display: none;
}

.chimpIcon:hover > .iconHover {
  display: inline-block;
}

.comingsoon {
  background: linear-gradient(
    180deg,
    #fdcdb8 0%,
    #ffc8e2 20.6%,
    #ffeeed 45.1%,
    #f8f4e5 61.6%,
    #fbd8f1 78.1%,
    #f6c5e8 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.comingsoonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.comingsoonBox {
  color: #393d63;
  position: relative;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 300px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
}
.comingsoonBox > p {
  margin: 5px;
}
.comingsoonTitle {
  font-size: 25px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(10px);
  width: 90%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -17px;
}
.comingsoonContainer {
  height: 100vh;
}
.headerInput {
  display: none;
}
@media (max-width: 768px) {

  .comingsoon {
    height: 100%;
  }
  .comingsoonContainer {
    height: 100vh;
  }
  .dashboard {
    height: 100svh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
  }
  .mainDashboard {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px;
    gap: 5px;
    height: calc(100svh - 125px);
  }
  .main {
    width: 100%;
    min-width: 0;
    height: calc(100svh - 160px);
    margin: 10px;
  }
.left{
  min-width: 420px;
  width: 100%;
}
.left.profile {
display: none;
}
.right.profile {
  display: none;
  }
  .right {
    width: 100vw;
  }
  .link > .linkedin-solid-svg {
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  .link:hover > .linkedin-solid-svg {
    display: none;
    width: 25px;
    height: 25px;
  }

  .link > .linkedin-gradient-svg {
    display: none;
    width: 25px;
    height: 25px;
  }

  .link:hover > .linkedin-gradient-svg {
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  .link1 > .facebook-solid-svg {
    display: inline-block;
    width: 20px;
    height: 21px;
  }

  .link1:hover > .facebook-solid-svg {
    display: none;
    width: 20px;
    height: 21px;
  }

  .link1 > .facebook-grad-svg {
    display: none;
    width: 20px;
    height: 21px;
  }

  .link1:hover > .facebook-grad-svg {
    display: inline-block;
    width: 20px;
    height: 21px;
  }

  .link2 > .twitter-solid-svg {
    display: inline-block;
    width: 18px;
    height: 21px;
  }

  .link2:hover > .twitter-solid-svg {
    display: none;
    width: 18px;
    height: 21px;
  }

  .link2 > .twitter-grad-svg {
    display: none;
    width: 18px;
    height: 21px;
  }

  .link2:hover > .twitter-grad-svg {
    display: inline-block;
    width: 18px;
    height: 21px;
  }
  .headerInput {
    display: flex;
  }
  .right .chat {
    display: none;
  }
  .dashWelcome{
    width: calc(100% - 20px) !important;
top: 60px !important;
height: calc(100svh - 165px) !important;
height: none;
  }
}
.swiper {
  width: 90vw;
 box-shadow: none;
 backdrop-filter: 0;
 margin-left: 20px;
}
.mySwiper{
  width: 100%;
  padding-left: 20px;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  backdrop-filter: 0;
  margin-left: 30px;
  height: 100%;
}


.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom {
display: none !important;
}
.teamContainer{

 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}
.member {
  width: 200px;
  height: 220px;
  background: #ffffff80;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 #0000000d, inset .5px 1px 1px 0 #fff;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

}
.namesContainer{
  margin: 0;
  height: 50px;
  width: 180px;
  background: #ffffff80;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 #0000000d, inset .5px 1px 1px 0 #fff;
  display: flex;
position: absolute;
top: -25px;
left: 10px;
justify-content: center;
align-items: center;
flex-direction: column;
}
.memberImage {
  width: 150px;
  height: 150px;

object-fit:cover
}
.name{
  margin: 0;
  color: #393d63;
  font-size: 18px;
  font-weight: 600;
}
.name.role{
  color: #393d63;
  font-size: 16px;
  font-weight: 300;
}
.container{
  background: linear-gradient(
    180deg,
    #fdcdb8 0%,
    #ffc8e2 20.6%,
    #ffeeed 45.1%,
    #f8f4e5 61.6%,
    #fbd8f1 78.1%,
    #f6c5e8 100%
  );
  padding-top: 50px;
  padding-bottom: 50px;
}
.title{
  font-size: 48px;
  color: #393d63;
  text-align: center;

}