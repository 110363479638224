.mainContainer {
  width: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 200px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mainText {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #393d63;
  font-size: 48px;
  margin: 0;
}
.highlight {
  color: var(--white);
}
.highlightedPeriod {
  color: var(--white);
  font-size: 48px;
  font-weight: 700;
}
.article {
  color: #393d63;
  position: relative;
}
.subText {
  color: #393d63;
  font-size: 48px;
}
.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 70px;
  gap: 40px;
}
.singleCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 20px;
  padding-top: 70px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  position: relative;
  margin-top: 20px;
}
.cardTitle {
  color: #393d63;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 20px;
  width: 75%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: left;
  align-items: center;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}
.cardText {
  font-size: 16px;
  font-weight: 300;
  margin-top: 0;
  color: #393d63;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px 25px;
  border-radius: 100px;
  background: #432c5b;
  color: white;
  border: 0;
  cursor: pointer;
}
.button:hover {
  background-color: #61487C;
  color: var(--white);
}
.cardTitleMobile{
  display: none;
}
@media (max-width: 1150px) {
  .mainText {
    flex-wrap: wrap;
    font-size: 40px;
  }
  .subText {
    font-size: 40px;
  }
  .cardContainer {
    flex-direction: column;
  }
}
@media (max-width: 1450px) {
  .mainText {
    flex-wrap: wrap;
    font-size: 40px;
  }
  .subText {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .mainContainer {
    max-width: 0;
  }
  .container {
    padding: 40px 20px 0px 20px;
  }
  .mainText {
    font-size: 20px;
    max-width: 320px;
    height: auto;
  }
  .textContainer {
    width: 100%;
  }
  .subText {
    font-size: 20px;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .highlightedPeriod {
    font-size: 20px;
  }
  .cardContainer {
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
  }
  .singleCard {
    border: 0;
    padding: 20px;
    transition: height 0.3s ease;
    height: 50px;
    margin-top: 0;
  }
  .cardTitle {
display: none;
  }
  .cardTitleMobile{
    display: flex;
  }
  .cardText {
    display: none;
    font-size: 12px;
  }

  .singleCard:hover .cardText {
    display: flex;
  }

  .singleCard:hover {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(161, 161, 161, 0.25);
    height: 150px;
  }
  .openFirst {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(161, 161, 161, 0.25);
    height: 150px;
  }
  .openFirst .cardText {
    display: flex;
  }
  .openSecond {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(161, 161, 161, 0.25);
    height: 150px;
  }
  .openSecond .cardText {
    display: flex;
  }
  .openThird {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(161, 161, 161, 0.25);
    height: 150px;
  }
  .openThird .cardText {
    display: flex;
  }
  .button{
    display: flex;
height: 50px;
padding: 10px 25px;
flex-direction: column;
justify-content: center;
align-items: center;
color: #FFF;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: auto;
margin-right: auto;
margin-top: 40px;
  }
}

@media (max-height: 560px){
  .mainText {
    font-size: 20px;
    max-width: 320px;
    height: auto;
  }
  .subText {
    font-size: 20px;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .highlightedPeriod {
    font-size: 20px;
  }
  .singleCard{
    margin-top: 0;
  }
  .cardTitle{
    font-size: 16px;
  }
  .cardText{
    font-size: 14px;
  }
}