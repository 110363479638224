.mainContainer {
  display: flex;
  background-color: var(--black);
}
.container {
  display: flex;
  width: 100%;
  margin: 80px 0px;
  flex-direction: column;
  align-items: center;
}
.title {
  color: var(--white);
  margin: 0;
  font-size: 48px;
  margin-bottom: 30px;
}
.cardsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 40px;
}
.singleCard {
  width: 320px;
  min-width: 320px;
  border-radius: 24px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 50px;
}
.imageContainer {
  display: flex;
  width: 90%;
  padding: 25px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
background: rgba(255, 255, 255, 0.10);

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
backdrop-filter: blur(10px);
position: absolute;
left: 50%;
transform: translateX(-50%);
top: -43px;
}
.content {
  margin: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contentTitle {
  margin-bottom: 30px;
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
}
.contentLI {
  color: rgba(255, 255, 255, 0.75);

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  padding-bottom: 10px;
}
.contentUL {
  padding: 0;
  height: 100%;
}
.button {
  width: 100%;
  display: flex;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  border-radius: 100px;
  background-color: white;
  border: 0;
  cursor: pointer;
}
.button:hover {
  background-color: #61487C;
  color: white;
}
.plus {
  display: none;
}
.imageContainerMobile{
  display: none;
}
@media (max-width: 1150px) {
  .cardsContainer {
    flex-direction: column;
  }
  .singleCard{
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .mainContainer {
    min-width: 0;
  }
  .contentTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 14px;
  }
  .container {
    margin: 40px 20px;
    gap: 20px;
  }
  .cardsContainer {
    flex-direction: column;
    margin-top: 0px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 0;
  }
.contentUL{
  width: 100%;
}
  .contentLI {
    opacity: 0;
    transition: opacity 0.1s ease;
    color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .button {
    display: none;
  }
  .plus {
    display: flex;
    position: absolute;
    right: -3px;
    top: -5px;
  }

  .content {
    position: relative;
    display: flex;
    align-items: flex-start;
  }
  .contentUL:nth-child(2){
    align-items: center;
  }
  .expand .contentLI {
    opacity: 1;
  }
  .singleCard{
    padding-top: 0px;
margin-top: 0;
  }
  .expand .cardButton{
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  .cardButton {
    width: 100%;
    display: flex;
    height: 50px;
    padding: 10px 25px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
    background-color: white;
    border: 0;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.1s ease;
    color: #393D63;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .cardButton:hover {
    background-color: #61487C;
    color: white;
  }
  .imageContainer{
    display: none;
  }
  .imageContainerMobile{
    display: flex;
    height: 140px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.10);
box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
border-top-left-radius: 10px;
border-top-right-radius: 10px;
  }
}
@media (max-height: 560px){
  .title{
    font-size: 25px;
  }
}