.mainConatiner {
  background-color:var(--black);
  width: 100%;
  position: relative;
}
.container {
  display: flex;
  padding: 0px 200px 80px 200px;
  position: relative;
  flex-direction: column;
  height: 100%;
}
.backgroundVideo {
  position: absolute;
  top: 30px;
  right: 10px;
  width: 100vw;
  height: 100%;
}
.leftContainer {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--white);
  font-size: 48px;
  text-align: center;
  padding-top: 50px;
  margin: 0;
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.cards.show {
  opacity: 1;
}
.singleCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
background: rgba(255, 255, 255, 0.10);
box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
position: relative;
padding: 20px;
padding-left: 60px;
}
.iconContainer {
  width: 85px;
  height: 85px;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.10);


  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -30px;
  transform: translateY(-50%);

}
.iconContainer>svg{
  width: 40px;
height: 40px;
}
.subTitle {
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  margin: 0px 0px 5px 10px;
}
.text {
  color: var(--white-sub-text);
  margin: 0px 0px 0px 10px;
  font-size: 16px;
}
.button {
  width: 136px;
  display: flex;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 100px;
  background: white;
  color: #000;
  border: 0;
  margin-top: 50px;
  cursor: pointer;
}
.button:hover {
  background-color: #61487C;
  color: white;
}
.cardContent {
  display: flex;
  flex-direction: column;
}
.subTitleMobile {
  display: none;
}
.backgroundMobileVideo{
  display: none;
}
.titleMobile{
  display: none;
}
.iconContainerMobile{
display: none;
}
@media (max-width: 1050px) {
  .mainConatiner {
    background-color: var(--black);
    min-width: 0;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .backgroundVideo {
    display: none;
  }
  .container {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }
  .title {
    text-align: center;
    padding-top: 0px;
    margin: 0;
    margin-top: 20px;
    color: #fff;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .image {
    width: 240px;
  }
  .rightContainer {
    justify-content: center;
  }
  .singleCard {
    padding: 16px 12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    flex-direction: column;
    align-items: self-start;
  }
  .cards {
    margin: 20px 0px 40px 0px;
    width: 100%;
  }
  .cardContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .iconContainer {
 display: none;
  }
  .iconContainerMobile {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
background: rgba(255, 255, 255, 0.10);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
     }
     .iconContainerMobile >svg{
      height: 24px;
     }
  .subTitleMobile {
    display: flex;
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 5px 10px;
  }
  .subTitle {
    display: none;
  }
  .text {
    font-size: 12px;
    margin: 0;
  }
  .button {
    display: flex;
    height: 50px;
    padding: 10px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: white;
    color: #000;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    width: auto;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .backgroundMobileVideo{
    display: flex;
  }
}
@media (max-width: 768px) {

  .mainConatiner {
    min-width: 0;
    flex-direction: column;
    align-items: center;
  }
  .container {
    margin: 0px 20px;
    height: 100%;
  }
  .title {
    margin-top: 20px;
    color: #fff;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .titleMobile{
    margin: auto;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .image {
    width: 240px;
  }
  .rightContainer {
    justify-content: center;
  }
  .singleCard {
    padding: 16px 12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    
    flex-direction: column;
    align-items: self-start;
  }
  .cards {
    margin: 20px 0px 40px 0px;
    gap: 20px;
  }
  .cardContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .iconContainer {
    width: 40px;
    height: 40px;
  }
  .iconContainer > svg {
    width: 20px;
  }
  .subTitleMobile {
    display: flex;
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 5px 10px;
  }
  .subTitle {
    display: none;
  }
  .text {
    font-size: 12px;
  }
  .button {
    display: flex;
    height: 50px;
    padding: 10px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: white;
    color: #000;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    width: auto;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .backgroundMobileVideo{
    display: flex;
    height: 100%;
    width: 100%;
  }
 .cards{
    opacity: 1;
  }
}
@media (max-height: 560px){

  .subTitle{font-size: 12px;}
  .text{
    font-size: 13px;
  }
  .cards{
    gap: 15px;
  }
  .button{
    margin-top: 5px;
  }
  .backgroundVideo{
    height: 90%;
  }
}
@media (max-height: 720px){

  .subTitle{font-size: 12px;}
  .text{
    font-size: 13px;
  }
  .cards{
    gap: 15px;
  }
  .button{
    margin-top: 5px;
  }
  .backgroundVideo{
    height: 90%;
  }
}