.menuContainer {
  width: 260px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 1px 1px 4px 2px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(25px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  opacity: 0;
}
.cover {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.searchContainerMobile {
  display: flex;
  height: 28px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0.5px 1px 2px 0px rgba(0, 0, 0, 0.1) inset;
  border: 0;
}
.searchInput {
  display: flex;
  height: 22px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 10px;
  background: #fcfcfc;

  border: 0;
}
.searchInput:focus {
  outline: 0;
}
.newChatContainer {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(10px);
  flex-direction: row;
  height: 20px;
  margin-bottom: 20px;
  position: fixed;
  width: 82%;
  bottom: 20px;
}
.newChatTitle {
  margin: 0;
  color: #393d63;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 165%;
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.menuContainer.open {
  opacity: 1;
  animation: slideIn 0.3s forwards;
}

.menuContainer.closed {
  animation: slideOut 0.3s forwards;
}
.singleCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
}
.content {
  width: 70%;
}
.addedTitle {
  color: #393d63;
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.cardIcon {
  display: flex;
  width: 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 10px 0px 0px 10px;
  background: #a7caff;
}
.title {
  color: #393d63;
  padding-left: 10px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.subTitle {
  color: #65677d;
  padding-left: 10px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.added {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.iconBubble {
  display: flex;
  width: 20px;
  height: 20px;

  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.historyTitle {
  color: #393d63;
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.historyDate {
  color: #65677d;
  margin: 0;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.historySubtitle {
  color: #393d63;
margin: 0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.historyContent {
  padding: 5px 10px;
  border-radius: 10px;
background: rgba(255, 255, 255, 0.90);
box-shadow: 0.5px 1px 1px 0px #FFF inset;
}
.icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
