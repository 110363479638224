/* MAIN START*/
.newEventContainer {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 740px;
  height: 85vh;
  margin: 20px 0px;
  margin-bottom: 20px;
gap: 10px;
  display: flex;
  flex-direction: column;

}
.newEventContainerSuccessfull{
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 740px;
  height: 85vh;
  margin: 20px 0px;
  margin-bottom: 20px;
gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.successfullContainer{
padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);

}
.newEventContainerSuccessfullMobile{
  height: 85vh;
  margin-bottom: 20px;
gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.successfullWrapper{
  display: flex;
padding: 10px 20px;
flex-direction: row;
align-items: flex-start;
gap: 22px;
}
.successfullText{
  color: var(--Text-dark, #393D63);
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 34px; /* 170% */
margin: 0;
}
.scrollable {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: scroll;

}
.scrollable::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}
.createButton {
  display: inline-flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--light-purple, #61487c);
  color: #fff;
  width: 170px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  position: absolute;
  bottom: 20px;
  right: 90px;
}
.createButtonMobile{
  display: none;
}

/* UPLOAD START*/
.back {
  color: var(--light-purple, #61487c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 15px;
}
.title {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.subTitle {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.imageContainer {
  position: relative;
}
.cover {
  width: 100%;
  height: 374px;
  background-color: #d9d9d958;
  border-radius: 10px;
  position: absolute;
  top: 0;
}
.green.plus {
  background: #93e292;
}
.image {
  height: 374px;
  width: 100%;
}
.uploadContainer {
  display: inline-flex;
  padding: 20px 14px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  border: 2px solid #f8f7fa;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 5;
}
.plus {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
}
.uploadCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f8f7fa;
}
.uploadText {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  width: 130px;
  text-align: center;
}
.openUploadContainer {
  display: inline-flex;
  padding: 20px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
}
.dropZone {
  border-radius: 10px;
  background: #f8f7fa;
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed transparent;
}
.dragOver {
  border: 2px dashed #393d63;
}
.dropTitle {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.ul {
  display: flex;
  gap: 70px;
  padding-left: 20px;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.openTitle {
  margin: 0;
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.subtext {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.inputContainer {
  display: flex;
  height: 20px;
  padding: 12px 20px;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
}
.input {
  display: flex;
  width: 650px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  padding: 12px 20px;

  height: 30px;
  border: 0;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.error {
  border: red 1px solid;
}
.errorContainer {
  position: absolute;
  top: 9px;
  right: 30px;
}
.input:focus {
  outline: none;
}
/* EVENT TITLE START*/
.eventTitleContainer {
  margin-top: 10px;
  display: inline-flex;
  padding: 20px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
  height: auto;
  transition: height 0.3s ease;
}
.expanded.eventTitleContainer {
  height: 100%;
}
.chars {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
/* LOCATION START*/
.locationContainer {
  display: inline-flex;
  padding: 20px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: 500px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
  transition: height 0.3s ease;
  margin-top: 10px;
}
.expandedLocation.locationContainer {
  height: 500px;
}
.datesContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dateInputContainer {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  width: 200px;
  height: 30px;
  padding: 12px 20px;
  align-items: center;
  gap: 20px;
}
.example {
  height: 40px;
  width: 150px;
  text-align: left;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: 0;
  border: 0;
}
.timeInputContainer {
  display: flex;
  width: 100px;
  height: 30px;
  padding: 12px 20px;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  position: relative;
}
.timeInput {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 30px;
  width: 50px;
  border: none;
  background: 0;
}
.buttonContainer {
  display: flex;
  gap: 20px;
}
.button {
  display: flex;
  height: 25px;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  color: #65677d;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}
.highlighted {
  color: white;
  background: #432c5b;
}
.displayedDate {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.onlineContainer {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
  display: flex;
  height: 30px;
  padding: 12px 20px;
  width: 640px;
  align-items: center;
  gap: 20px;
}
.urlInput {
  width: 100%;
  height: 40px;
  border: 0;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.urlInput:focus {
  outline: none;
}
/* CAPACITY START*/
.capacityContainer {
  display: inline-flex;
  padding: 20px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  transition: height 0.3s ease;
  position: relative;
  margin-top: 20px;
}
.expandedCapacity.capacityContainer {
  height: 100%;
}
.capacityInput {
  width: 60px;
  display: flex;
  height: 16px;
  padding: 12px 0px 12px 20px;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
}
.numberInput {
  width: 20px;
  height: 20px;
  border: 0;
}
.numberInput:focus {
  outline: none;
}
.opacityUnlimited {
  display: flex;
  height: 45px;
  padding: 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  border: 0;
  color: #393d63;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.opacityUnlimitedHighlighted {
  background: var(--light-purple, #61487c);
  color: white;
}
/* DESCRIPTION START*/
.descriptionContainer {
  margin-top: 20px;
  display: inline-flex;
  padding: 20px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
height: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(2px);
  transition: height 0.3s ease;
}
.expandedDescription.descriptionContainer {
  height: 100%;
  padding-bottom: 70px;
}
.editor {
  width: 100%;
  position: relative;
}
@media (max-width: 768px) {
  .newEventContainerMobile{
display: flex;
flex-direction: column;
gap: 10px;
padding: 20px 10px;
height: calc(100svh - 130px);
  }
  .back{

    font-size: 12px;
  }
  .title{
    font-size: 14px;

  }
.subTitle{
  color: var(--Text-dark, #393D63);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.cover{
  height: 180px;
}
.image{
  height: 180px;
}
.uploadContainer{
padding: 12px 8px;
gap: 5px;
width: 90px;
}
.uploadText{
  font-size: 12px;
  width: 90px;
}
.plus{
  width: 24px;
  height: 24px;
}
.openTitle{
  font-size: 16px;
}
.text{
  font-size: 14px;
}
.subtext{
  font-size: 12px;
}
.openUploadContainer{
  padding: 10px;
}
.ul{
  flex-direction: column;
  gap: 10px;
}
.fileInputContainer {
  position: relative;
}

.hiddenInput {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.customFileInput {
  color: var(--text-light, #65677D);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
border: 1px solid var(--text-light, #65677D);
display: flex;
padding: 5px 10px;
justify-content: center;
align-items: center;
gap: 10px;
width: 70px;
}

.customFileInput:active {
  background: #432C5B;
  color: white;
}
.eventTitleContainer{
  padding: 10px;
}
.input{
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 6px;
}
.locationContainer{
  padding: 10px;
}
.datesContainer{
  flex-direction: column;
  gap: 10px;
}
.dateInputContainer{
  width: 88%;
}
.timeInputContainer{
width: 70%;
  gap: 20px;
}
.onlineContainer{
  width: 88%;
}
.capacityContainer{
  padding: 10px;
}
.capacityInput{
  display: flex;
  width: 90px;
  padding: 10px;
  align-items: center;
  gap: 6px;
}
.opacityUnlimited{
  display: flex;
height: 36px;
padding: 5px 15px;
justify-content: center;
align-items: center;
gap: 10px;
font-size: 12px;
}
.descriptionContainer{
  padding: 10px;

}
.expandedDescription.descriptionContainer{
  padding-bottom: 80px;
}
.createButton{
  display: none;
}
.createButtonMobile{
  display: inline-flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--light-purple, #61487c);
  color: #fff;
  width: 170px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  margin: 10px;
}
}