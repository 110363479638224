.container {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;
  flex-direction: column;
  overflow-y: auto;
  max-width: 340px;
  min-width: 250px;
  width: 100%;
  overflow-x: hidden;
}
.dragging {
  opacity: 0.5; /* Example: Change opacity during drag */
}
.title {
  margin: 20px;
  color: var(--dashbord-headers);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}
.scrollText {
  color: var(--dashbord-normalText);
  text-align: center;
  font-size: 12px;
  margin: 15px 20px 20px 20px;
}
.cardsContainer {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  margin-bottom: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;
}
.cardsContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.card {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  display: flex;
  height: 56px;
  padding: 10px;
  transition: height 0.5s ease;
  cursor: pointer;
  
}
.cardContent {
  display: flex;
}
.cardText {
  text-wrap: nowrap;
  width: 150px;
  color: var(--dashbord-headers);
  max-width: 90%;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  margin: 0;
}
.cardSubText {
  text-wrap: nowrap;
  color: var(--dashbord-normalText);
  width: 150px;
  max-width: 90%;
  font-size: 12px;
  overflow: hidden;
  margin: 0;
}
.cardDate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.date {
  color: var(--dashbord-normalText);
  text-align: right;
  font-size: 11px;
}
.cardBuble {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ee3d72;
}
.cardDrag {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.cardUploadedIcon{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.cardDescription {
  display: none;
  color: var(--dashbord-normalText);
  font-size: 12px;
}
.cardButton {
  display: flex;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: var(--dashbord-primary);
  color: var(--white);
  font-size: 12px;
  border: 0;
  position: relative;
  right: 0;
}
.buttonContainer {
  display: flex;
  justify-content: right;
  display: none;
}
.card:hover {
  background: rgba(255, 255, 255, 0.9);
}
.card:hover .cardDrag {
  opacity: 1;
}
.expanded {
  height: 300px;
}

.expanded .cardDrag {
  opacity: 1;
}
.expanded .cardText {
  width: 150px;
  text-wrap: wrap;
  margin-bottom: 10px;
}
.expanded .cardSubText {
  text-wrap: wrap;
}
.expanded .bubble {
  display: none;
}

.expanded .cardsContainer {
  display: flex;
  flex-direction: column;
}
.expanded .cardDescription {
  display: flex;
}
.expanded .buttonContainer {
  display: flex;
}

@media (max-width: 768px) {
  .container {
    display: none;
  }
}
@media (max-height: 560px){
  .title{
    margin: 10px 20px;

  }
  .cardsContainer{
    margin: 10px 20px;
  }
  .scrollText{
    margin-top: 0;
  }
}