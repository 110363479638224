.container {
  display: flex;
  border-radius: 20px;
background: rgba(255, 255, 255, 0.25);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;
backdrop-filter: blur(10px);
  flex-direction: column;
  overflow-y: auto;
  max-width: 340px;
  min-width: 250px;
  overflow-x: hidden;
height: 100%;

}
.title {
  color: var(--dashbord-headers);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  margin: 20px 20px 0 20px;
}
.subTitle {
  color: #65677d;
  margin: 0;
  margin-top: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cardsContainer {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  margin: 0px 20px 0 20px;
  padding: 0;
  margin-top: 20px;
}
.cardsContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.cards {
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 5px;
  height: 18px;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  align-items: center;
  justify-content: space-between;
}
.cardTitle {
  text-wrap: nowrap;
  color: #393d63;
  margin: 0;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  min-width: 100px;
  width: 100%;
}
.icon {
  border-radius: 100px;
  background: #a7caff;
  display: flex;
  width: 18px;
  height: 18px;

  justify-content: center;
  align-items: center;
}
.icon > svg {
  width: 10px;
  height: 10px;
}
.scrollText {
  color: var(--dashbord-normalText);
  text-align: center;
  font-size: 12px;
  margin: 20px;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .container{
    display: none;
  }
}
@media (max-height: 560px){

  .title{
    margin: 10px 20px;
  }
  .container{
    gap: 0px;
  }
  .scrollText{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  }