.mainContainer {
  display: flex;
  background: linear-gradient(180deg, #FFDBCC 0%, #FCD8E9 56.1%, #F9CEED 100%);
}
.container {
  display: flex;
  width: 100%;
  margin: 80px 200px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.title {
  color: var(--black);
  font-size: 48px;
  margin: 0;
}
.selection {
  width: 362px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.highlight {
  color: var(--orange-light);
}
.select {
  width: 181px;
  cursor: pointer;
  background-color: var(--input-bg);
  color: var(--black);
  height: 60px;
  text-align: center;
  font-size: 20px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: background-color 0.3s ease;
}

.selected {
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  display: flex;
  width: 181px;
  height: 60px;
  background: #432C5B;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.select1 {
  width: 181px;
  cursor: pointer;
  color: var(--black);
  background-color: var(--input-bg);
  height: 60px;
  text-align: center;
  font-size: 20px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: background-color 0.3s ease;
}
.selected1 {
  cursor: pointer;
  color: white;
  text-align: center;
  font-size: 20px;
  display: flex;
  width: 181px;
  height: 60px;
  background: #432C5B;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.tableContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
.table {
  width: 100%;
  margin-top: 60px;
  border-spacing: 0px;
  position: relative;
  padding-top: 50px;
  border-radius: 10px;
background: rgba(255, 255, 255, 0.50);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;
}
.tableHeader {
  display: flex;
  align-items: center;
  text-align: left;
  height: 82px;
  padding: 0;
  padding-left: 30px;
  color: #393D63;
flex: 1;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.tableItem {
  color: var(--black);
  font-size: 20px;
  padding: 0;
  padding-left: 30px;
  color: rgba(57, 61, 99, 0.75);

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.tableHeader1 {
  display: flex;
  align-items: center;
  text-align: center;
  width: 150px;
  height: 82px;

  padding: 0;
  color: #393D63;

font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;

}
.tableHeader2 {
  display: flex;
  align-items: center;
  text-align: center;
  height: 82px;
width: 100px;
  padding: 0;
  color: #393D63;

font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;

}
.tableItem1 {
  text-align: center;
  width: 30px;
  height: 82px;

  padding: 0;
  color: rgba(57, 61, 99, 0.75);

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.tableItem2 {
  text-align: center;
  width: 280px;
  height: 82px;

  padding: 0;
  color: rgba(57, 61, 99, 0.75);

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.border {
  border-bottom: var(--black) 1px solid;
  width: 100%;
}
.highlighted {
  background: rgba(255, 255, 255, 0.25);
}
.button{
  display: none;
}
.tableHeaders{
position: absolute;
top: -45px;
left: 50%;
transform: translateX(-50%);
width: 90%;
border-radius: 10px;
background: rgba(255, 255, 255, 0.50);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(10px);
}
.tableHeadersMobile{
  display: none;
}
@media (max-width: 768px) {
  .mainContainer {
    min-width: 0;
   
  }
  .container {
width: 100%;
    margin: 40px 20px;
  }
  .tableItem1 {
    display: none;
  }
  .tableHeader1 {
    display: none;
  }
  .title{
    font-size: 20px;
  }
  .select{
    font-size: 14px;
    width: 140px;
    height: 40px;
  }
  .select1{
    font-size: 14px;
    width: 140px;
    height: 40px;
  }
  .selected{
    font-size: 14px;
    width: 140px;
    height: 40px;
  }
  .selected1{
    font-size: 14px;
    width: 140px;
    height: 40px;
  }
  .selection{
    width: auto;
  }
  .tableContainer{
    width: auto;

  }
  .table{
    width: 100%;
    padding-top: 0;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #FFF inset;

margin-top: 20px;
box-shadow: 0px 0px 8px 0px rgba(161, 161, 161, 0.25);
  }
  .tableHeader{
    width: auto;
    display: flex;
    padding: 20px 15px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    color: #393D63;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .tableItem{
    width: auto;
    font-size: 12px;
padding: 16px 30px;
align-items: flex-start;
align-self: stretch;
margin: 5px;
  }
  .tableItem1{
  display: none;
  }
  .tableItem2{
display: none;
  }
  .highlighted{
    width: auto;
    background: rgba(255, 255, 255, 0.50);
  margin: 5px;  
  }
  .tableHeader{
    height: 10px;
    border: 0;
  }
  .button{
    width: 100%;
    display: flex;
    height: 50px;
    padding: 10px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
background: #FBBF31;
color: #000;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
border: 0;
margin: 20px 40px;
  }
  .buttonContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button:hover{
  background-color: var(--orange-dark);
  color: var(--white);
  }
  .tableHeaders{
    display: none;
  }
  .tableHeadersMobile{
    display: flex;
    justify-content: center;
  }
}
@media (max-height: 560px){
  .container{
    gap: 10px;
  }
  .title{
    font-size: 25px;
  }
  .selection{
    width: 250px;
  }
  .select,.select1{
    font-size: 15px;
    height: 40px;
  }
  .selected,.selected1{
    font-size: 15px;
    height: 40px;
  }
  .tableHeaders{
    height: 40px;
    top: -20px;
  }
  .tableHeader,.tableHeader1,.tableHeader2{
    font-size: 15px;
    height: 40px;
  }
  .table{
    padding-top: 40px;
  }
  .tableItem,.tableItem1,.tableItem2{
    height: 50px;
  }

}

@media (max-height: 720px){
  .container{
    gap: 10px;
  }
  .selection{
    width: 250px;
  }
  .select,.select1{
    font-size: 15px;
    height: 40px;
  }
  .selected,.selected1{
    font-size: 15px;
    height: 40px;
  }
  .tableHeaders{
    height: 40px;
    top: -20px;
  }
  .tableHeader,.tableHeader1,.tableHeader2{
    font-size: 15px;
    height: 40px;
  }
  .table{
    padding-top: 40px;
  }
  .tableItem,.tableItem1,.tableItem2{
    height: 50px;
  }

}