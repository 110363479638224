.mainContainer {
  display: flex;
  background: linear-gradient(180deg, #FFDBCC 0%, #FCD8E9 56.1%, #F9CEED 100%);
  flex-direction: column;
  padding-top: 40px;

}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 200px;
  margin-bottom: 80px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 0.5px 1px 1px 0px #FFF inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  padding: 40px;
}
.contactTitle {
  color: var(--black);
  text-align: center;
  font-size: 48px;
}
.formContainer {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 100%;
gap: 90px;
}
.left {
  width: 300px;
  position: relative;
}
.contact {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  border-radius: 10px;
background: rgba(255, 255, 255, 0.50);
box-shadow: 0.5px 1px 1px 0px #FFF inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(10px);
padding: 20px;
justify-content: center;
align-items: center;
position: absolute;
left: -70px;

}
.contact:nth-child(2){
  position: absolute;
left: -70px;

top: 125px;
}
.iconContainer {
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactInfo {
  margin-left: 20px;
  width: 200px;
}
.text {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
}
.subText {
  color: var(--sub-text-grey);
  font-size: 13px;
  margin-top: 0;
}
.right {
  width: 100%;

}
.names {
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 0;
  padding-left: 25px;
  background-color: var(--input-bg);
  color: var(--input-placeholder);
font-family: Poppins;
  font-size: 16px;
}
.names:focus {
  outline: var(--input-grey) 1px solid;
}
.inputDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  gap: 30px;
  position: relative;
  width: 100%;
}
.inputs {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  gap: 30px;
  position: relative;
  margin: 0;
  width: 100%;
}
.input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 15px 25px;
  border-radius: 20px;
  border: 0;
  padding: 0;
  padding-left: 20px;
  background-color: var(--input-bg);
  color: var(--input-placeholder);

font-family: Poppins;
  font-size: 16px;
}
.input:focus {
  outline: var(--input-grey) 1px solid;
}
.inputMessage {
  display: flex;
width: 100%;
  height: 106px;
  gap: 10px;
  border-radius: 20px;
  border: 0;
  padding: 0;
  padding-left: 25px;
  padding-bottom: 30px;
  background-color: var(--input-bg);
  color: var(--input-placeholder);

  font-family: Poppins;
  font-size: 16px;
}
.inputMessage:focus {
  outline: var(--input-grey) 1px solid;
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  display: flex;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 100px;
  background: #432C5B;
color: white;

  border: 0;
  cursor: pointer;
}
.button:hover {
  background-color: #61487C;
}
@media (max-width: 1200px) {
  .left{
    display: none;
  }
}
@media (max-width: 1000px) {
  .mainContainer{
    min-width: 0;
  }
  .container{
    margin: 80px 200px;
    padding: 16px;
  }
  .formContainer{
    display: flex;
    flex-direction: column;
  }
  .contactTitle{
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .right{
    padding: 0;
  }
  .inputs{
    flex-direction: column;
    gap: 0px;
  }
  .names{
    height: 32px;
    font-size: 10px;
  }
  .input{
    height: 32px;
    font-size: 10px;
  }
  .inputMessage{
    height: 83px;
    font-size: 10px;
  }
  .inputDiv{
    gap: 0px;
    margin-bottom: 20px;
  }
  .button{
    
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact{
    display: none;
  }
}
@media (max-width: 768px) {
  .mainContainer{
    min-width: 0;
  }
  .container{
    margin: 40px 20px;
    padding: 16px;
  }
  .contactTitle{
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }
  .left{
    display: none;
  }
  .right{
    padding: 0;
  }
  .inputs{
    flex-direction: column;
    gap: 0px;
  }
  .names{
    height: 32px;
    font-size: 10px;
  }
  .input{
    height: 32px;
    font-size: 10px;
  }
  .inputMessage{
    height: 83px;
    font-size: 10px;
  }
  .inputDiv{
    gap: 0px;
    margin-bottom: 20px;
  }
  .button{

    color: #FFF;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .contact{
    display: none;
  }
 
}
@media (max-height: 560px){

  .container{
    padding: 20px;
  }
  .contact{
    padding: 10px;
    margin-bottom: 10px;
  }
  .contact:nth-child(2){
    position: absolute;
  left: -70px;
  
  top: 105px;
  }
  .formContainer{
    margin-top: 10px;
  }
  .inputDiv{
    margin-bottom: 10px;
  }
  .inputMessage{
    height: 60px;
  }
}
@media (max-height: 720px){

  .container{
    padding: 20px;
  }
  .contact{
    padding: 10px;
    margin-bottom: 10px;
  }
  .contact:nth-child(2){
    position: absolute;
  left: -70px;
  
  top: 105px;
  }
  .formContainer{
    margin-top: 10px;
  }
  .inputDiv{
    margin-bottom: 10px;
  }
  .inputMessage{
    height: 60px;
  }
}