.mobileContainer {
  width: 95vw;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.profiles {
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.3);
  width: 178px;
}
.profile {
  color: rgba(57, 61, 99, 0.5);

  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.selected {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15),
    0px 3px 1px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdownWrapper {
  display: flex;
  gap: 0px;
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  justify-content: center;
  align-items: center;
}
.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15),
  0px 3px 1px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  width: 70px;
  padding: 10px;
  gap: 10px;
  display: none;
  position: absolute;
  top: 20px;
}

.listItem {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.expandDropdown.dropdown {
  display: flex;
  z-index: 10;
}
.filtersWrapper {
  display: flex;
  position: relative;
  height: 20px;
  animation: expandFilters 1s ease forwards;
}
.filterContainer {
  display: flex;
  gap: 10px;
  width: 100vw;
  left: 100vw;

  padding: 0;
}
.filterContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
@keyframes expandFilters {
  from {
    right: 100vw;
    width: 0;
  }
  to {
    width: 100%;
    right: 0; /* End at left: 0 to move in from right to left */
  }
}
.filter {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: #65677d;
  border: 0;
font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.selectedFilter {
  border-radius: 10px;
  background: var(--purple, #432c5b);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: #fff;

font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}
/* SINGLE CARD */
.singleCardContainer {
  width: 80%;
  height: 139px;
  border-radius: 10px;
  background: rgb(255, 255, 255);

  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.userType {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardText {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: right;
}
.viewButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  display: flex;

  height: 20px;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #393d63;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 14px;
  border: 0;
  background: transparent;
}
.viewButton:active {
  background: var(--purple, #432c5b);
  color: white;
}
.addButton {
  display: flex;
  height: 20px;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--purple, #432c5b);
  color: #fff;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}
.addButton:active {
  background: var(--light-purple, #61487c);
}
.cardSubtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
.userInfoContainer {
  display: flex;
  gap: 10px;
  width: 100%;
}
.userInfoWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.userImageContainer {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.userImage {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.scoreContainer {
  width: 100%;
  margin-right: 20px;
  position: relative;
}
.score {
  color: #fbbf31;

  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.scoreHighlighted {
  color: rgba(251, 191, 49, 0.5);
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.infoIcon {
  position: absolute;
  right: -20px;
  top: -10px;
}
.infoBubble {
  display: flex;
  flex-direction: column;
  width: 123px;
  padding: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid rgba(251, 191, 49, 0.5);
  background: rgba(251, 191, 49, 0.5);
  backdrop-filter: blur(5px);
  position: absolute;
  left: -85px;
  top: -25px;
}
.bubbleText {
  color: var(--Text-dark, #393d63);
font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 3px;
}
.bubbleAddon {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid rgba(251, 191, 49, 0.5);

  position: absolute;
  right: -9px;
  top: 20px;
}
.filtertext {
  color: #393d63;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* EVENTS*/
.eventsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.eventsContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}
.eventTite {
  color: #393d63;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0px 0 20px 0;
  overflow: scroll;
  height: 200px;
  width: 100%;
}
.cardsContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.plus {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #432c5b;
  color: white;
  font-size: 20px;
  font-weight: 300;
}

/*personal charts */
.personalChartContainer {
  height: 75svh;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.back {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 15px;
}
.chartCardsContainer {
  width: 100%;
  display: flex;
  gap: 20px;
  overflow: scroll;
}
.chartCardsContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}
.chartSingleCard {
  display: flex;
  width: 111px;
  height: 64px;
  padding: 8px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
}
.chartNumber {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.chartText {
  color: var(--text-light, #65677d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.connectionsContainer {
  width: 94%;
  height: 65%;
  overflow: scroll;
}
.connectionsContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}
.choicesTitle {
  color: var(--Text-dark, #393d63);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
