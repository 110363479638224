.container {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(2px);
  flex-direction: column;
  overflow-y: auto;
  max-width: 680px;
  min-width: 350px;
  height: 100%;
}
.dragging {
  opacity: 0.5; /* Example: Change opacity during drag */
}
.title {
  color: var(--dashbord-headers);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin: 20px;
}
.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px;
}
.filter {
  cursor: pointer;
  display: flex;
  height: 15px;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  color: var(--dashbord-normalText);
  font-family: Poppins;
  font-size: 12px;
}
.selected {
  background: var(--dashbord-primary);
  color: var(--white);
}
.cardsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-bottom: 0;
  gap: 10px;
  overflow-y: scroll;
  scrollbar-width: thin; 
}
.cardsContainer::-moz-scrollbar {
  display: none; /* Firefox */
}
.cardsContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.feeds::-moz-scrollbar {
  display: none; /* Firefox */
}
.feeds{
  scrollbar-width: none; 
}
.singleCard {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  display: flex;
  align-items: self-start;
  align-self: stretch;
  height: 64px;
  transition: height 0.3s ease;
  cursor: pointer;
  border: 2px;
  scroll-snap-align: start;
}
.cardContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.iconContainer {
  display: flex;
  width: 64px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0.5px 1px 1px 0px #fff inset;
}
.cardTitle {
  color: var(--dashbord-primary);
  font-size: 14px;
  font-weight: 600;
  margin: 1px;
  text-wrap: nowrap;
}
.cardSubTitle {
  color: #65677d;

  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1px;
  white-space: nowrap;
  overflow: hidden;
  min-width: 150px;
  max-width: 318px;
}
.options {
  display: flex;
  margin-left: auto;
}
.content {
  display: flex;
  margin: 10px;
  overflow: hidden;
}
.cardBuble {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ee3d72;
}
.cardDrag {
  width: 30px;
  opacity: 0;
}
.singleCard:hover .cardDrag {
  opacity: 1;
}
.singleCard:hover {
  background: rgba(255, 255, 255, 0.9);
}
.saveContainer {
  width: 56px;
  display: flex;
  justify-content: center;
}
.save {
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dateContainer {
  width: 80px;
  color: var(--dashbord-normalText);
  text-align: right;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filteredFeed {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.timetext {
  color: var(--dashbord-normalText);
  font-size: 12px;
  margin: 0;
}

.expanded {
  height: auto;
  background: rgba(255, 255, 255, 0.9);
}
.expanded .cardSubTitle {
  text-wrap: wrap;
}
.expanded .cardDrag {
  display: none;
}
.cardDragExpanded {
  visibility: hidden;
  width: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
.cardDescription {
  color: var(--dashbord-normalText);
  margin: 0 10px;
  font-size: 12px;
  height: 0px;
  overflow: scroll;
  max-height: 70px;
  animation: collapseDescription 0.3s ease forwards;
}
.cardDescription::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.expanded .cardDescription {
  animation: expandDescription 0.3s ease forwards;
}
.collapsed .cardDescription {
  opacity: 0;
}
.collapsed .cardContent {
  height: 64px;
}
.expanded .cardContent {
  height: 100%;
}
.cardDescriptionSmall {
  color: var(--dashbord-normalText);
  margin: 10px;
  font-size: 12px;
  height: 0px;
  overflow: hidden;
  animation: collapseDescriptionSmall 0.3s ease forwards;
}

.expanded .cardDescriptionSmall {
  animation: expandDescriptionSmall 0.3s ease forwards;
}
.collapsed .cardDescriptionSmall {
  opacity: 0;
}
.expanded .cardDragExpanded {
  visibility: visible;
  display: flex;
  width: 30px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
}
.singleCard:hover .cardDragExpanded {
  visibility: visible;
}
.buttonConatiner {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: auto;
  opacity: 0;
  transition: opacity 0s;
  gap: 10px;
  overflow: hidden;
  padding-bottom: 10px;
}
.expanded .buttonConatiner {
  opacity: 1;
  transition: opacity 0s;
}
.buttonJobs {
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: var(--white);
  color: var(--dashbord-headers);
  font-size: 12px;
  border: 0;
  cursor: pointer;
}
.button {
  display: flex;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: var(--dashbord-primary);
  color: var(--white);
  border: 0;
  font-size: 12px;
  cursor: pointer;
}
.button:hover {
  background: var(--dashbord-primaryHighlighted);
  color: var(--white);
}
.buttonJobs:hover {
  background: var(--dashbord-primaryHighlighted);
  color: var(--white);
}
.scrollText {
  color: var(--dashbord-normalText);
  text-align: center;
  font-size: 12px;
  margin: 20px;
  margin-top: 15px;
}
.saveContainerMobile {
  display: none;
}
.filterContainerMobile {
  display: none;
}
.mobileFilters {
  display: none;
}
@keyframes expandDescription {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}

@keyframes collapseDescription {
  from {
    height: 100%;
  }
  to {
    height: 0px;
  }
}
@keyframes expandDescriptionSmall {
  from {
    height: 0px;
  }
  to {
    height: 50px;
  }
}

@keyframes collapseDescriptionSmall {
  from {
    height: 50px;
  }
  to {
    height: 0px;
  }
}
@media (max-width: 768px) {
  .container {
    margin: 0;
    background: none;
    box-shadow: none;
    border-radius: 0;
    height: 50vh;
    backdrop-filter: none;
    overflow-x: hidden;
  }
  .singleCard {
    width: 95vw;
  }
  .filterContainer {
    display: none;
  }
  .title {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .cardsContainer {
    margin: 0;
    width: 100%;
  }
  .iconContainer {
    width: 30px;
  }
  .iconContainer > svg {
    width: 12px;
  }
  .cardTitle {
    font-size: 10px;
  }
  .cardSubTitle {
    font-size: 10px;
    width: 170px;
  }
  .cardDescription {
    font-size: 10px;
    width: 93%;
    margin-left: 10px;
  }
  .options {
    margin: 0;
  }
  .saveContainer {
    display: none;
  }
  .cardContent {
    justify-content: left;
  }
  .saveContainerMobile {
    display: flex;
    margin-right: auto;
    margin-left: 20px;
  }
  .scrollText {
    display: none;
  }
  .cardDrag {
    display: none;
  }
  .cardDragExpanded {
    display: none;
  }
  .expanded .cardDragExpanded {
    display: none;
  }
  .buttonConatiner {
    margin-right: 25px;
  }
  .filteredFeed {
    width: 90vw;
  }
  .timetext {
    width: 100%;
  }
  .mobileFilters {
    display: flex;
    position: absolute;
    right: 20px;
  }
  .filtersContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 30px;
    width: 100%;
    overflow: auto;
  }
  .filterContainer {
  }
  .filterBubble {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background: var(--dashbord-primary);
    color: white;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .filterTitle {
    color: #393d63;
    margin: 0;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .filterContainerMobile {
    height: 60px;
    display: flex;
    gap: 10px;
    overflow-y: scroll;
    width: 100vw;
    left: 100vw;
    animation: expandFilters 1s ease forwards;
    padding: 0;
  }
  .filterContainerMobile .title {
    display: none;
  }
  .filterContainerMobile::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
  .filtersContainer::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  @keyframes expandFilters {
    from {
      right: 100vw;
      width: 0;
    }
    to {
      width: 100%;
      right: 0; /* End at left: 0 to move in from right to left */
    }
  }
}
/*FEED SMALL*/
.feedContainer {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  flex-direction: column;
  overflow-y: auto;
  max-width: 340px;
  min-width: 250px;
  overflow: hidden;
  height: 100%;
}
.feeds {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  gap: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.feeds::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
@media (max-width: 768px) {
  .feedContainer {
    display: none;
  }
}
@media (max-height: 560px) {
  .title {
    margin: 10px 20px;
  }
  .cardsContainer {
    margin: 10px 20px;
  }
  .scrollText {
    margin-top: 0;
  }
  .filterContainer {
    margin: 10px 20px;
  }
}
.cardAdded{
  width: 40px;
  margin-top: auto;
  margin-bottom: auto;
}