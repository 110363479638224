.container {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(2px);
  flex-direction: column;
  overflow-x: scroll;
  height: 100%;
  padding: 20px;
  padding-bottom: 0;
}
.uploadsContainer {
  display: flex;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.28) 19.57%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0.5px 1px 1px 0px #fff inset;
  backdrop-filter: blur(2px);
  flex-direction: column;
  overflow-x: scroll;
  padding: 20px;
  padding-bottom: 0;
  height: 100%;
}
.title {
  color: var(--dashbord-headers);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  gap: 10px;
  overflow-x: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;

}
.cardsContainer::-webkit-scrollbar{
width: 0;
background-color: transparent;
display: none;
}
.cardsContainer::-moz-scrollbar {
  display: none; /* Firefox */
}
.singleCard {
  display: flex;
  align-items: center;
  height: 56px;
  border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  justify-content: space-between;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  background: #432c5b;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.uploaded {
  background: none;
}
.singleCardTitle {
  color: #393d63;
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 10px;
  overflow: hidden;
  text-wrap: nowrap;
}
.drag {
  display: none;
  margin-left: 5px;
  margin-right: 20px;
}
.uploadedIcon {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.singleCard:hover .drag {
  display: flex;
}
.singleCard:hover {
  background: rgba(255, 255, 255, 0.9);
}
.scrollText {
  color: var(--dashbord-normalText);
  text-align: center;
  font-size: 12px;
  margin: 0 20px 20px 20px;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.plus {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #432c5b;
  color: white;
}
.cardsContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.modalContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: fixed; /* Change to fixed */
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%); /* Horizontally center the modal */
  z-index: 100;
}
.cover {
  width: 100vw;
  height: 120vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 50;
}
.modalButton {
  display: flex;

  width: 280px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #393d63;
  height: 47px;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
  border: 0;
  margin-bottom: 60px;
}
.optionsContainer {
  display: flex;
  width: 240px;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
}
.singleModalCard {
  height: 40px;
  display: flex;
  width: 240px;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
}
.modalIcon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px 0px 0px 10px;
  background: #432c5b;
}
.singleModalCardTitle {
  color: #393d63;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalButton:active {
  background: #432c5b;
  color: white;
}
.cardsContainerMobile {
  display: flex;
  width: 240px;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
}
.singleSmallCard {
  display: flex;
  width: 240px;
  height: 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0.5px 1px 1px 0px #fff inset;
}
.singleModalCard:active {
  background: #432c5b;
  box-shadow: none;
}
.singleModalCard:active .singleModalCardTitle {
  color: white;
}
.singleSmallCard:active {
  background: #432c5b;
  box-shadow: none;
}
.singleSmallCard:active .smallCardTitle {
  color: white;
}
.modalIconSmall {
  border-radius: 10px 0px 0px 10px;
  background: #432c5b;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}
.plus::-webkit-file-upload-button {
  visibility: hidden;
}

.plus::before {
  content: attr(placeholder);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white; /* Placeholder text color */
  cursor: pointer; /* Show cursor as pointer when hovering over the placeholder */
  /* Additional styling as needed */
}
.smallCardTitle {
  color: #393d63;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
}
@media (max-width: 768px) {
  .container {
    display: none;
  }
  .uploadsContainer {
    display: none;
  }
}
.dragging {
  opacity: 0.5; /* Example: Change opacity during drag */
}

.uploadbutton {
  background: #432c5b;
  height: 28px;
  width: 28px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.3s ease;
  color: white;
  position: relative;
}
.test {
  display: flex;
  font-size: 20px;
}
.test2 {
  display: flex;
  font-size: 16px;
  color: transparent;
  opacity: 0;
  visibility: hidden;
  transition: color 0.2s ease, opacity 0.2s ease;
  position: absolute;
}

.uploadbutton:hover .test2 {
  color: white;
  opacity: 1;
  visibility: visible;
}

.uploadbutton:hover .test {
  display: none;
}
.uploadbutton:hover {
  width: 90px;
  border-radius: 50px;
}
